import { formatDistanceToNowStrict } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { ContentCopy, Replay, Delete } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'

export const preInvestorListColumn = ({
	userInfo,
	copyOnClipBoard,
	handleOpenUpdatePreRegisterLinkModal,
	handleOpenDeletePreInvestorModal,
}) => [
	{
		header: 'Nombre',
		accessor: 'name',
		size: 'sm',
	},
	{
		header: 'Apellido',
		accessor: 'lastName',
		size: 'sm',
	},
	{
		header: 'Telefono',
		accessor: 'phone',
		size: 'sm',
	},
	{
		header: 'Email',
		accessor: 'email',
		size: 'sm',
	},
	{
		header: 'Registrado',
		accessor: 'registered',
		size: 'sm',
	},
	{
		header: 'Acciones',
		accessor: 'actions',
		cellAlign: 'right',
		headerAlign: 'right',
		size: 'sm',
		cell: ({ row, cell }) => {
			return (
				<>
					<div className='actions-right'>
						{!row.item.registered && (
							<>
								<Tooltip id='tooltip-top' title='Copiar link de Pre-Registro' placement='bottom'>
									<Button
										justIcon
										round
										simple
										onClick={() => copyOnClipBoard(row.item.registerUrl)}
										color='success'
										className='view'
									>
										<ContentCopy />
									</Button>
								</Tooltip>
								{formatDistanceToNowStrict(new Date(row.item.createdAt), {
									unit: 'day',
								}).startsWith(5) &&
									userInfo.isSupport && (
										<Tooltip id='tooltip-top' title='Renovar link de Pre-Registro' placement='bottom'>
											<Button
												justIcon
												round
												simple
												onClick={() => handleOpenUpdatePreRegisterLinkModal(row.item)}
												color='success'
												className='view'
											>
												<Replay />
											</Button>
										</Tooltip>
									)}
							</>
						)}
						<Tooltip id='tooltip-top' title='Borrar Pre-Registro' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => handleOpenDeletePreInvestorModal(row.item)}
								color='danger'
								className='view'
							>
								<Delete />
							</Button>
						</Tooltip>
					</div>
				</>
			)
		},
	},
]
