import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Loader from 'components/Loader/Loader'
import CustomInput from 'components/CustomInput/CustomInput'
import ReInvestPDLTable from './ReinvestPDLTable'
import { validationInfoTemplate } from '../helpers/validation-templates'
import { matchCurrencyToDecimal, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { RE_INVESTMENT_REGISTER_RESET } from 'local_redux/constants/investmentConstants'
import { getProjectsWithPDLToInvest } from 'local_redux/actions/projectAction'
import { PROJECT_PDL_TO_INVEST_LIST_RESET } from 'local_redux/constants/projectConstant'
import { getProfilesForPDL } from 'local_redux/actions/investorProfileActions'
import { INVESTORS_PROFILE_LIST_FOR_PDL_RESET } from 'local_redux/constants/investorProfileConstants'
import { registerPdlsByInvestment } from 'local_redux/actions/projectLiquidityActions'
import { PDL_REGISTER_BY_INVESTMENT_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

let projectData = []

const ReInvestmentPDLModal = ({
	openReinvestmentModal,
	setOpenReinvestmentModal,
	handleCloseReinvestAdminModal,
	showReinvestAdminInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projectdata, setProjectData] = useState([])
	const [amountToReinvest, setAmountToReinvest] = useState([])
	const [pendingReinvestmentAmount, setPendingReinvestmentAmount] = useState([])
	const [alert, setAlert] = useState(null)
	const [error, setError] = useState({ message: '', show: false })
	const [projectAddressToFilter, setProjectAddressToFilter] = useState('')
	const [filteredProjects, setFilteredProjects] = useState([])

	const { successPdlRegisterByInvestment, loadingPdlRegisterByInvestment } = useSelector(
		(state) => state.pdlRegisterByInvestment,
	)
	const { userInfo } = useSelector((state) => state.userLogin)
	const { profilesForPDL } = useSelector((state) => state.investorsProfileListForPDL)
	const {
		loadingPDLProjectsToInvestList,
		successPDLProjectsToInvestList,
		PDLProjectsToInvestListData,
		errorPDLProjectsToInvestList,
	} = useSelector((state) => state.projectsWithPDLToInvest)

	useEffect(() => {
		if (successPdlRegisterByInvestment) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>PDL registrado con éxito</div>
				</SweetAlert>,
			)
		}
	}, [successPdlRegisterByInvestment])
	useEffect(() => {
		if (!successPDLProjectsToInvestList) {
			dispatch(getProjectsWithPDLToInvest())
			dispatch(getProfilesForPDL())
		}
	}, [successPDLProjectsToInvestList])
	useEffect(() => {
		handleParams()
		return () => {
			projectData = []
		}
	}, [])
	useEffect(() => {
		if (successPDLProjectsToInvestList && showReinvestAdminInfo) {
			const data = PDLProjectsToInvestListData?.map((item) => {
				if (item?.pdlAvailable > 0) {
					item.view = true
					item.amountInvertible = item?.pdlAvailable
					item.invertible = true
				} else {
					item.view = false
				}
				return item
			})
			setProjectData(data.filter((project) => project.view === true && project.invertible === true))
		}
	}, [successPDLProjectsToInvestList, showReinvestAdminInfo])
	useEffect(() => {
		if (pendingReinvestmentAmount < 0 && error.show === false) {
			setError({
				message: `El monto maximo de reinversion disponible es ${matchNumberToCurrency(amountToReinvest)}`,
				show: true,
			})
		}
	}, [error])
	useEffect(() => {
		setFilteredProjects(
			projectdata.filter((project) =>
				project?.projectAddress?.toLowerCase().includes(projectAddressToFilter.toLowerCase()),
			),
		)
	}, [projectAddressToFilter, projectdata])

	const confirmSuccess = () => {
		setProjectData([])
		setAlert(null)
		setOpenReinvestmentModal(false)
		dispatch({ type: PROJECT_PDL_TO_INVEST_LIST_RESET })
		dispatch({ type: RE_INVESTMENT_REGISTER_RESET })
		dispatch({ type: INVESTORS_PROFILE_LIST_FOR_PDL_RESET })
		dispatch({ type: PDL_REGISTER_BY_INVESTMENT_RESET })
	}
	const handleParams = () => {
		const totalAmountInvested = projectData.reduce(
			(previousValue, currentValue) =>
				previousValue + Number(matchCurrencyToDecimal(currentValue.reinvestmentAmount.toString())),
			0,
		)
		const reinvestingAmount = matchCurrencyToDecimal(
			showReinvestAdminInfo.liquidation === 'benefits-only'
				? showReinvestAdminInfo.amount.toString()
				: showReinvestAdminInfo.toPay.toString(),
		)

		setAmountToReinvest(reinvestingAmount)
		setPendingReinvestmentAmount(reinvestingAmount - totalAmountInvested.toFixed(2))
	}
	const handleChange = (values, item) => {
		const project = projectData.filter((project) => project._id !== item._id)
		projectData = [
			...project,
			{ ...item, reinvestmentAmount: values.reinvestmentAmount, typeOfInterest: values.typeOfInterest },
		]
		handleParams()
		if (values.value > item.amountInvertible) {
			setError({
				message: `El monto maximo invertible es ${matchNumberToCurrency(item.amountInvertible)}`,
				show: true,
			})
		} else {
			setError({
				messaje: ``,
				show: false,
			})
		}
	}
	const handleInvestmentData = () => {
		if (!error.show) {
			if (pendingReinvestmentAmount > 0) {
				return setError({
					message: `Faltan ${matchNumberToCurrency(pendingReinvestmentAmount)} por invertir`,
					show: true,
				})
			}
			const profileInvestor = profilesForPDL?.find((e) => e?._id === showReinvestAdminInfo?.investorProfile?._id)
			const validation = profileInvestor
				? validationInfoTemplate({
						...profileInvestor,
						addressOfTheRepresentative: profileInvestor?.addressOfTheRepresentative || profileInvestor?.owner?.address,
				  })
				: false
			if (!validation.success) {
				setError({
					message: 'Faltan datos requeridos.',
					show: true,
				})
				return
			}
			const newPdls = []
			projectData.forEach((project) => {
				const amountInNumb = Number(project.reinvestmentAmount)
				if (amountInNumb > 0) {
					newPdls.push({
						project: project?._id,
						profileInvestor,
						amountPdl: amountInNumb,
						projectAddress: project?.projectAddress,
						maxSaleDate: project?.maxSaleDate,
						user: userInfo?._id,
						typeOfInterest: `${parseInt(project?.typeOfInterest)}%` || '12%',
					})
				}
			})

			dispatch(registerPdlsByInvestment({ investmentId: showReinvestAdminInfo?._id, pdls: newPdls }))
			return
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openReinvestmentModal}
			keepMounted
			onClose={handleCloseReinvestAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseReinvestAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				Reinvertir {showReinvestAdminInfo?.liquidation === 'benefits-only' ? ' Importe Inicial' : 'todo'}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{loadingPDLProjectsToInvestList ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader
								message={'Cargando proyecto'}
								config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					</GridContainer>
				) : (
					<>
						<div>
							<span className={classes.spanTitle}>€ A Reinvertir:</span>
							<span className={classes.spanTitle}>
								<b>{matchNumberToCurrency(amountToReinvest)}</b>
							</span>
						</div>
						<div>
							<span className={classes.spanTitle}>€ Pendientes de Reinvertir:</span>
							<span className={`${classes.spanTitle} ${pendingReinvestmentAmount < 0 ? classes.spanError : ''}`}>
								<b>{matchNumberToCurrency(pendingReinvestmentAmount)}</b>
							</span>
						</div>
						<GridContainer>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={'Buscar por dirección *'}
									id='project-address-filter'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: projectAddressToFilter,
										onChange: (e) => {
											setProjectAddressToFilter(e.target.value)
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
						<ReInvestPDLTable data={filteredProjects} projectData={projectData} handleChange={handleChange} />
					</>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					{(error.show || errorPDLProjectsToInvestList) && (
						<GridItem xs={12}>
							<SnackbarContent
								sx={{ width: 10 }}
								message={errorPDLProjectsToInvestList || error.message}
								color='warning'
							/>
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button
							color={successPdlRegisterByInvestment ? 'success' : 'primary'}
							onClick={handleInvestmentData}
							disabled={error.show || loadingPdlRegisterByInvestment}
							block
						>
							{loadingPdlRegisterByInvestment ? 'Reinvirtiendo...' : 'Reinvertir'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default ReInvestmentPDLModal
