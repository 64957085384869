import { cardTitle } from 'assets/jss/material-dashboard-pro-react'

const styles = () => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
})

export default styles
