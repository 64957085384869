import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import DownloadInvestmentContractsModal from 'components/DownloadInvestmentContractsModal/DownloadInvestmentContractsModal'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import TimeFilterCard from 'components/TimeFilterCard/TimeFilterCard'
import ReinvestmentModalPdlScreen from './components/ReInvestmentModalPdlScreen'
import ReInvestmentPDLModalPdlScreen from './components/ReInvestmentPDLModalPdlScreen'
import DownloadStatusModal from './components/DownloadStatusModal'
import {
	projectLiquiditiesLiquidatedColumns,
	projectLiquiditiesLiquidatedExcelColumns,
} from './helpers/project-liquidities-liquidated-columns'
import initialStateDates from './helpers/initialStateDates'
import { projectLiquiditiesLiquidatedMapper, excelMapper } from './mappers/projects-liquidities-liquidated-mapper'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { registerLog } from 'local_redux/actions/logActions'
import {
	getProjectsLiquiditiesLiquidated,
	getContractsInfoToDownloadPDL,
} from 'local_redux/actions/projectLiquidityActions'
import {
	PROJECT_LIQUIDITY_LIQUIDATED_RESET,
	GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET,
} from 'local_redux/constants/projectLiquidityConstants'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/pdlListScrenStyles'

const useStyles = makeStyles(styles)

const PdlLiquidatedScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const tableId = 1

	const { getFilteredDataRows } = useContext(FilterReactVirtualizedTableContext) //context of table

	const [projects, setProjects] = useState([])
	const [showReinvestAdminInfo, setShowReinvestAdminInfo] = useState({})
	const [openReinvestmentPDLModal, setOpenReinvestmentPDLModal] = useState(false)
	const [openReinvestmentModal, setOpenReinvestmentModal] = useState(false)
	const [openDownloadContractModal, setOpenDownloadContractModal] = useState(false)
	const [openDownloadStatusModal, setOpenDownloadStatusModal] = useState(false)
	const [urlsToDownload, setUrlsToDownload] = useState([])
	const [zipFileName, setZipFileName] = useState('')
	const [filterDate, setFilterDate] = useState(initialStateDates)

	const {
		loadingProjectLiquidityLiquidatedList,
		successProjectLiquidityLiquidatedList,
		projectLiquidityliquidatedList,
		errorProjectLiquidityLiquidatedList,
	} = useSelector(({ projectLiquidityLiquidated }) => projectLiquidityLiquidated)
	const {
		loadingContractsInfoToDownloadPDL,
		successContractsInfoToDownloadPDL,
		contractsInfoToDownloadPDL,
		errorContractsInfoToDownloadPDL,
	} = useSelector(({ contractsInfoToDownloadPDL }) => contractsInfoToDownloadPDL)
	const { userInfo } = useSelector(({ userLogin }) => userLogin)
	const { successPdlRegisterReinvestPdlInvestments } = useSelector(
		({ pdlRegisterReinvestPdlInvestments }) => pdlRegisterReinvestPdlInvestments,
	)
	const { successPdlRegisterReinvestment } = useSelector(({ pdlRegisterReinvestment }) => pdlRegisterReinvestment)

	useEffect(() => {
		dispatch(getProjectsLiquiditiesLiquidated(`?startDate=${filterDate.startDate}&endDate=${filterDate.endDate}`))

		return () => {
			dispatch({ type: PROJECT_LIQUIDITY_LIQUIDATED_RESET })
		}
	}, [])
	useEffect(() => {
		if (successProjectLiquidityLiquidatedList) {
			setProjects(projectLiquiditiesLiquidatedMapper(projectLiquidityliquidatedList))
		}
	}, [successProjectLiquidityLiquidatedList])
	useEffect(() => {
		if (successPdlRegisterReinvestPdlInvestments || successPdlRegisterReinvestment) {
			dispatch(getProjectsLiquiditiesLiquidated())

			return () => {
				dispatch({ type: PROJECT_LIQUIDITY_LIQUIDATED_RESET })
			}
		}
	}, [successPdlRegisterReinvestPdlInvestments, successPdlRegisterReinvestment])

	const handleCloseReinvestPDLModal = () => {
		setOpenReinvestmentPDLModal(false)
	}
	const handleCloseReinvestAdminModal = () => {
		setOpenReinvestmentModal(false)
	}
	const handleDownload = ({ urls, zipFileName, ids }) => {
		const logInfo = {
			type: {
				type: 'download',
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			dataDownloadInfo: {
				model: 'projectliquidities',
				field: 'contractUrl',
				docIds: ids,
			},
		}
		setOpenDownloadStatusModal(true)
		setOpenDownloadContractModal(false)
		setUrlsToDownload(urls)
		setZipFileName(zipFileName)
		dispatch(registerLog(logInfo))
	}
	const handleCloseStatus = () => {
		setOpenDownloadStatusModal(false)
		dispatch({ type: LOG_REGISTER_RESET })
	}

	return (
		<GridContainer>
			<GridItem xs={12} md={5}>
				<TimeFilterCard
					filterDate={filterDate}
					setFilterDate={setFilterDate}
					handleFilter={(dates) => {
						dispatch(getProjectsLiquiditiesLiquidated(`?startDate=${dates.startDate}&endDate=${dates.endDate}`))
					}}
					title='Inversiones entre'
				/>
			</GridItem>
			<GridItem xs={12} md={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Assignment />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>PDL liquidados</h4>
						<div style={{ display: 'flex', justifyContent: 'end' }}>
							{Boolean(getFilteredDataRows({ tableId })?.length) && (
								<ExcelDefaultExport
									excelName='PDL Liquidados'
									tableColumns={projectLiquiditiesLiquidatedExcelColumns({ tableId })}
									data={excelMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
								/>
							)}

							{(userInfo?.isSuper || userInfo?.isInvestorRelations) && (
								<Button style={{ margin: '5px 0px 15px 12px' }} onClick={() => setOpenDownloadContractModal(true)}>
									Descargar contratos
								</Button>
							)}
						</div>
					</CardHeader>
					<CardBody>
						<ReactVirtualizedTable
							tableId={tableId}
							columns={projectLiquiditiesLiquidatedColumns({
								userInfo,
								setShowReinvestAdminInfo,
								setOpenReinvestmentPDLModal,
								setOpenReinvestmentModal,
							})}
							data={projects}
							loading={Boolean(loadingProjectLiquidityLiquidatedList)}
							success={Boolean(successProjectLiquidityLiquidatedList)}
							error={errorProjectLiquidityLiquidatedList}
							emptyText={'No se encontraron inversiones.'}
						/>
					</CardBody>
					{openReinvestmentModal && (
						<ReinvestmentModalPdlScreen
							handleCloseReinvestAdminModal={handleCloseReinvestAdminModal}
							openReinvestmentModal={openReinvestmentModal}
							setOpenReinvestmentModal={setOpenReinvestmentModal}
							showReinvestAdminInfo={showReinvestAdminInfo}
						/>
					)}
					{openReinvestmentPDLModal && (
						<ReInvestmentPDLModalPdlScreen
							handleCloseReinvestAdminModal={handleCloseReinvestPDLModal}
							openReinvestmentModal={openReinvestmentPDLModal}
							setOpenReinvestmentModal={setOpenReinvestmentPDLModal}
							showReinvestAdminInfo={showReinvestAdminInfo}
						/>
					)}
					{openDownloadContractModal && (
						<DownloadInvestmentContractsModal
							openModal={openDownloadContractModal}
							closeModal={() => setOpenDownloadContractModal(false)}
							handleDownload={handleDownload}
							typeScreen={'liquidated'}
							loadingContractsInfoToDownload={loadingContractsInfoToDownloadPDL}
							successContractsInfoToDownload={successContractsInfoToDownloadPDL}
							contractsInfoToDownload={contractsInfoToDownloadPDL}
							errorContractsInfoToDownload={errorContractsInfoToDownloadPDL}
							getContractsInfoToDownload={getContractsInfoToDownloadPDL}
							GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET={GET_CONTRACTS_INFO_TO_DOWNLOAD_PDL_RESET}
						/>
					)}
					{openDownloadStatusModal && (
						<DownloadStatusModal
							openModal={openDownloadStatusModal}
							closeModal={handleCloseStatus}
							urls={urlsToDownload}
							setUrlsToDownload={setUrlsToDownload}
							zipFileName={zipFileName}
						/>
					)}
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default PdlLiquidatedScreen
