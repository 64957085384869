import { ArrowForward } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
export const pdlLiquidateScreenColumns = (handleSelectProject) => {
	return [
		{
			header: 'Dirección',
			size: 'sm',
			accessor: 'projectAddress',
		},
		{
			header: 'Inversion total',
			size: 'sm',
			accessor: 'pdlTotalInversion',
		},
		{
			header: 'Acciones',
			size: 'sm',
			accessor: 'actions',
			headerAlign: 'right',
			cellAlign: 'right',
			disabledSort: true,
			disabledSearch: true,
			cell: ({ row, cell }) => (
				<div style={{ textAlign: 'right' }}>
					<Button
						justIcon
						round
						simple
						onClick={() => {
							handleSelectProject(row.item)
						}}
						color='success'
						className='toInvest'
					>
						<ArrowForward />
					</Button>
				</div>
			),
		},
	]
}

export const pdlLiquidateTableExcelExport = [
	{
		accessor: 'name',
		header: 'Perfil Inversor',
	},
	{
		accessor: 'iban',
		header: 'IBAN',
	},
	{
		header: 'Proyecto',
		accessor: 'projectAddress',
	},
	{
		accessor: 'amountPdl',
		header: 'Importe Inversión',
	},
	{
		accessor: 'liquidation',
		header: 'Liquidación',
	},
	{
		accessor: 'incomeRecivedDate',
		header: 'Ingreso recibido',
	},
	{
		accessor: 'liquidityDate',
		header: 'Fecha de Liquidación',
	},
	{
		accessor: 'typeOfInterest',
		header: 'Intereses anual',
	},
	{
		accessor: 'fee',
		header: 'Intereses',
	},
	{
		accessor: 'retention',
		header: 'Retención',
	},
	{
		accessor: 'retentionFee',
		header: 'Importe Retención',
	},
	{
		accessor: 'toPay',
		header: 'A pagar',
	},
]
