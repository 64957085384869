import { investmentLiquidationOptions } from 'shared/general-config/investment-contrants'
import { changeIncomeReciveDate } from 'shared/helpers/functions-helpers'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { calculateToTIR } from 'shared/helpers/functions-helpers'

const liquidatedInvestmentMapper = (investments) => {
	return investments.map((item) => {
		if (item.investorProfile.email === 'sergiogozalo@yahoo.es') {
			console.log('item: ', item.investorProfile.businessName, item)
		}
		const reinvestedIn = (childInvesments) => {
			return childInvesments
				.map((investment) => ` ${investment?.projectAddress || ''} ${investment?.deleted ? '(ANULADO)' : ''}`)
				.join()
		}
		const amount = !isNaN(replaceStringToNum(item.amount)) ? replaceStringToNum(item.amount) : 0

		const benefit = !isNaN(replaceStringToNum(item.benefit)) ? replaceStringToNum(item.benefit) : 0
		const contractStatus = item.itWasSigned
			? item.signed && item.contractUrl
				? 'Firmado'
				: 'Pendiente de Firma'
			: 'Firma y Contrato pendientes'
		return {
			id: item._id,
			item: item,
			projectAddress: item.project?.projectAddress?.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			investorProfile: item.investorProfile?.businessName,
			email: item.investorProfile?.email,
			amount: item.amount,
			status: item.project?.status,
			benefit: item.benefit,
			contractUrl: item?.contractUrl,
			retention: item?.retention || item.investorProfile.retention,
			toPay: item.toPay,
			incomeRecivedDate: item.incomeRecivedDate,
			dateLiquidation: item?.depositDate,
			startDateUpdated: item.project.startDateUpdated,
			singedContract: contractStatus,
			tir: item?.investorTIR,
			hasComments: item?.hasComments ? 'Tiene' : 'No tiene',
			investorTIR:
				item.incomeRecivedDate && item.depositDate && item.benefit && item.amount
					? `${calculateToTIR(
							changeIncomeReciveDate(item.incomeRecivedDate, item.project.startDateUpdated),
							item.depositDate,
							amount + benefit,
							item.amount,
					  )}%`
					: '0%',
			liquidation:
				item.investorProfile.liquidation === investmentLiquidationOptions.reinvest &&
				item.liquidation === investmentLiquidationOptions.pay
					? 'Finalmente Pagados'
					: item.liquidation === investmentLiquidationOptions.pay && !item.wasInvestment
					? 'Recibidos'
					: item.liquidation === investmentLiquidationOptions.benefitsOnly && !item.wasInvestment
					? 'Solo Beneficios Recibidos'
					: item.wasInvestment
					? `${
							item.liquidation === investmentLiquidationOptions.reinvest
								? item.childsPdl?.length && !('deleted' in item.childsPdl[0])
									? 'Para PDL del proyecto ' + reinvestedIn(item.childsPdl) || item.project.projectAddress
									: 'Reinvertidos en ' + reinvestedIn(item.childInvestmentsProjectPopulated) ||
									  item.project.projectAddress
								: item.childsPdl?.length && !('deleted' in item.childsPdl[0])
								? 'Importe inicial para PDL del proyecto ' + reinvestedIn(item.childsPdl) || item.project.projectAddress
								: 'Importe inicial reinvertido en ' + reinvestedIn(item.childInvestmentsProjectPopulated) ||
								  item.project.projectAddress
					  }`
					: 'Pendiente de Reinversión',
		}
	})
}

export default liquidatedInvestmentMapper
