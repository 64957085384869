import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import Tippy from '@tippyjs/react'
import { Assignment } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { INVESTMENT_LAST_LIST_RESET } from 'local_redux/constants/investmentConstants'
import { getLastInvestmentList } from 'local_redux/actions/investmentActions'
import { getInvestmentsByProfile } from 'local_redux/actions/investmentActions'
import LastInvestmentsByProfile from 'components/LastInvestmentsByProfile/LastInvestmentsByProfile'
import styles from './styles/profileLastInvestmentScreenStyles'

const useStyles = makeStyles(styles)

const ProfileLastInvestmentScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [filterState, setFilterState] = useState([])
  const [rowData, setRowData] = useState([])

  const { loadingInvestmenLastProfile, investmenLastProfileData, successInvestmenLastProfile } = useSelector(
    (state) => state.investmensLastList
  )
  const { investmentsByProfileData } = useSelector((state) => state.investmentsByProfile)
  useEffect(() => {
    if (successInvestmenLastProfile) {
      const investments = investmenLastProfileData.map((item) => {
        return {
          id: item._id,
          businessName: item.businessName,
          lastInvestment: format(new Date(item.investments.createdAt), 'dd-MM-yyyy'),
          actions: <div className='actions-right'></div>,
        }
      })
      setData(investments)
    } else {
      dispatch(getLastInvestmentList())
      dispatch(getInvestmentsByProfile())
    }
  }, [setData, investmenLastProfileData])

  useEffect(() => {
    return () => dispatch({ type: INVESTMENT_LAST_LIST_RESET })
  }, [dispatch])

  useEffect(() => {
    let investments = investmentsByProfileData?.find((item) => item.investor._id === rowData.id)
    setFilterState(investments)
  }, [rowData])

  const handleOver = useCallback((row) => {
    setRowData(row)
  }, [])

  return (
    <GridContainer>
      {loadingInvestmenLastProfile ? (
        <GridItem xs={2} style={{ margin: '0 auto' }}>
          <Loader />
        </GridItem>
      ) : (
        investmenLastProfileData && (
          <>
            <GridItem xs={12}>
              <Card>
                <CardHeader color='primary' icon>
                  <CardIcon color='primary'>
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Perfiles última Inversión</h4>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: 'Razón Social',
                        accessor: 'businessName',
                        size: 'big',
                        Cell: ({ cell }) => {
                          return (
                            <>
                              <div
                                onMouseEnter={(e) => handleOver(cell.row.original)}
                                className={classes.cursorPointer}
                              >
                                <Tippy
                                  maxWidth={535}
                                  popperOptions={{ strategy: 'fixed' }}
                                  className={classes.tippy}
                                  interactive
                                  placement='bottom'
                                  zIndex={10000}
                                  content={
                                    <GridContainer>
                                      {filterState?.investor && <LastInvestmentsByProfile filterState={filterState} />}
                                    </GridContainer>
                                  }
                                >
                                  <span>{cell.row.original.businessName}</span>
                                </Tippy>
                              </div>
                            </>
                          )
                        },
                      },
                      {
                        Header: 'Fecha última inversión ',
                        accessor: 'lastInvestment',
                        size: 'small',
                      },
                      {
                        Header: 'Acciones',
                        accessor: 'actions',
                        size: 'small',
                      },
                    ]}
                    displayButtons={true}
                    data={data}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </>
        )
      )}
    </GridContainer>
  )
}

export default ProfileLastInvestmentScreen
