import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Loader from 'components/Loader/Loader'
import CustomBox from 'components/CustomBox/CustomBox'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { updateProject } from 'local_redux/actions/projectAction'
import { PROJECT_UPDATE_RESET } from 'local_redux/constants/projectConstant'
import { getAcceptedProjectOffer } from 'local_redux/actions/projectAction'
import { LiquidatedProjectContext } from 'contexts/liquidatedProjectContext'
import styles from '../styles/updateProjectModalStyles'

const useStyles = makeStyles(styles)

const UpdateProjectModal = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const [data, setData] = useState({})

	const { updateProjectModal, updateProjectInfo, handleCloseUpdateProject } = useContext(LiquidatedProjectContext)
	const { loadingProjectUpdate, errorProjectUpdate, successProjectUpdate } = useSelector((state) => state.projectUpdate)
	const { loadingProjectAcceptedOffer, errorProjectAcceptedOffer, successProjectAcceptedOffer, acceptedOffer } =
		useSelector((state) => state.acceptedOffer)

	useEffect(() => {
		setData(updateProjectInfo)
	}, [])
	useEffect(() => {
		dispatch(getAcceptedProjectOffer(updateProjectInfo._id))
		return () => dispatch({ type: PROJECT_UPDATE_RESET })
	}, [dispatch])

	const updateProjectHandler = (e) => {
		e.preventDefault()

		const projectData = {
			_id: data._id,
			projectCost: data.projectCost,
			startDateDossier: data.startDateDossier,
			finishDateDossier: data.finishDateDossier,
			startDateUpdated: data.startDateUpdated,
			finishDateUpdated: data.finishDateUpdated,
			projectSaleValueUpdated: data.projectSaleValueUpdated,
			projectSaleValueDossier: data.projectSaleValueDossier,
			totalInvestment: data.totalInvestment,
			admissibleCoinvestment: data.admissibleCoinvestment,
		}
		dispatch(updateProject(projectData))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={updateProjectModal}
			keepMounted
			onClose={handleCloseUpdateProject}
			aria-labelledby='project-update-title'
			aria-describedby='project-update-description'
		>
			<form onSubmit={updateProjectHandler}>
				<DialogTitle id='project-update-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseUpdateProject}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Editar Proyecto {data.projectAddress}</h4>
				</DialogTitle>

				<DialogContent id='project-update-description' className={classes.modalBody}>
					{loadingProjectAcceptedOffer ? (
						<GridContainer>
							<GridItem xs={12} className={classes.loaderWrapper}>
								<Loader />
							</GridItem>
						</GridContainer>
					) : (
						<GridContainer spacing={3}>
							<GridItem xs={12} md={6}>
								<CustomInput
									labelText='Inversión Total *'
									id='total-investment'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: matchCurrencyToDecimal(data.totalInvestment),
										onChange: (e) =>
											setData({
												...data,
												totalInvestment: e.target.value,
												projectCost: (
													Number(e.target.value) *
													(Number(data?.admissibleCoinvestment || 0) / 100)
												).toFixed(2),
											}),
										type: 'number',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} md={6}>
								<CustomInput
									labelText='% Coinversión Admisible * Un valor entre 0 y 100'
									id='admissible-coinvest'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: data.admissibleCoinvestment,
										onChange: (e) =>
											setData({
												...data,
												admissibleCoinvestment: e.target.value,
												projectCost: (
													Number(matchCurrencyToDecimal(data.totalInvestment)) *
													(e.target.value / 100)
												).toFixed(2),
											}),
										type: 'number',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12}>
								<CustomInput
									labelText='Importe Invertible *'
									id='invertible-cost'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: data.projectCost,
										disabled: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} md={6}>
								<div style={{ marginTop: '2rem' }}>
									<CustomBox title='Estimado en Dossier'>
										<GridContainer>
											<GridItem xs={12} style={{ marginBottom: 30 }}>
												<DatePicker
													fullWidth
													value={data.startDateDossier}
													onChange={(e) => setData({ ...data, startDateDossier: e })}
													label='Fecha de inicio Dossier *'
													className={classes.dateInput}
												/>
											</GridItem>
											<GridItem xs={12} style={{ marginBottom: 20 }}>
												<DatePicker
													fullWidth
													value={data.finishDateDossier}
													minDate={data.startDateDossier}
													minDateMessage={'Por favor revise este valor'}
													onChange={(e) => setData({ ...data, finishDateDossier: e })}
													label='Fecha de fin Dossier *'
													className={classes.dateInput}
												/>
											</GridItem>
											<GridItem xs={12}>
												<CustomInput
													labelText='Valor de Venta en Dossier *'
													id='project-sale-value-dossier'
													formControlProps={{
														fullWidth: true,
													}}
													inputProps={{
														value: matchCurrencyToDecimal(data.projectSaleValueDossier),
														onChange: (e) => {
															setData({ ...data, projectSaleValueDossier: e.target.value })
														},
														type: 'number',
														required: true,
													}}
												/>
											</GridItem>
										</GridContainer>
									</CustomBox>
								</div>
							</GridItem>
							<GridItem xs={12} md={6}>
								<div style={{ marginTop: '2rem' }}>
									<CustomBox title='Nueva Estimación'>
										<GridContainer>
											<GridItem xs={12} style={{ marginBottom: 30 }}>
												<DatePicker
													fullWidth
													value={data.startDateUpdated}
													onChange={(e) => setData({ ...data, startDateUpdated: e })}
													label='Fecha de inicio Actualizada *'
													className={classes.dateInput}
												/>
											</GridItem>

											<GridItem xs={12} style={{ marginBottom: 20 }}>
												<DatePicker
													fullWidth
													value={data.finishDateUpdated}
													onChange={(e) => setData({ ...data, finishDateUpdated: e })}
													label='Fecha de fin Actualizada *'
													className={classes.dateInput}
												/>
											</GridItem>
											<GridItem xs={12}>
												<CustomInput
													labelText='Valor de Venta en Actualizado *'
													id='project-sale-value-updated'
													formControlProps={{
														fullWidth: true,
														disabled: acceptedOffer?.amount ? true : false,
													}}
													inputProps={{
														value: matchCurrencyToDecimal(
															acceptedOffer ? String(acceptedOffer.amount) : data.projectSaleValueUpdated,
														),
														onChange: (e) => {
															setData({ ...data, projectSaleValueUpdated: e.target.value })
														},
														type: 'number',
														required: true,
													}}
												/>
											</GridItem>
											{errorProjectUpdate && (
												<GridItem xs={12} sm={12}>
													<SnackbarContent message={errorProjectUpdate} color='danger' />
												</GridItem>
											)}
										</GridContainer>
									</CustomBox>
								</div>
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button type='submit' color={successProjectUpdate ? 'success' : 'primary'} block>
								{loadingProjectUpdate
									? 'Actualizando'
									: successProjectUpdate
									? 'Proyecto Actualizado'
									: 'Actualizar Proyecto'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default UpdateProjectModal
