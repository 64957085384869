import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import SelectInputGender from 'components/SelectInputGender/SelectInputGender'
import InputsUser from './components/InputsUser'
import InputsLegalProfile from './components/InputsLegalProfile'
import { matchGender } from 'shared/matchData/matchData'
import { OWNER_PROFILE_WITH_USER_REGISTER_RESET } from 'local_redux/constants/ownersProfileConstants'
import { registerProfileOwnerWithUser } from 'local_redux/actions/ownersProfileActions'
import styles from './styles/ownerUserRegisterUserStyle'

const useStyles = makeStyles(styles)

const OwnersUserRegisterScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const initialState = {
		address: '',
		bankAccountNumber: '',
		businessAddress: '',
		businessEmail: '',
		businessName: '',
		businessPhone: '',
		cif: '',
		country: '',
		dni: '',
		email: '',
		gender: '',
		lastName: '',
		liquidation: 'pay',
		location: 'Madrid',
		name: '',
		nif: '',
		personType: '',
		phone: '',
		phone2: '',
		postalCode: '28001',
		prescriber: '',
		province: 'Madrid',
		representativeLastName: '',
		representativeName: '',
		representativeNif: '',
		residenceCertificate: '',
		retention: '19%',
		sponsor: '',
	}

	const [data, setData] = useState(initialState)
	const [errorGender, setErrorGender] = useState('')
	const [alert, setAlert] = useState(null)

	const {
		loadingOwnerProfileWithUserRegister,
		errorOwnerProfileWithUserRegister,
		successOwnerProfileWithUserRegister,
	} = useSelector((state) => state.ownerProfileWithUserRegister)

	useEffect(() => {
		if (successOwnerProfileWithUserRegister) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					onCancel={() => setAlert(null)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Propietario Registrado Correctamente
				</SweetAlert>,
			)
		}
	}, [successOwnerProfileWithUserRegister])
	useEffect(() => {
		return () => dispatch({ type: OWNER_PROFILE_WITH_USER_REGISTER_RESET })
	}, [dispatch])

	const confirmSuccess = () => {
		setData(initialState)
		setErrorGender('')
		dispatch({ type: OWNER_PROFILE_WITH_USER_REGISTER_RESET })
		setAlert(null)
	}
	const registerInvestorHandler = (e) => {
		e.preventDefault()

		if (!data.gender) {
			return setErrorGender('Por favor ingrese el genero.')
		}

		const dataToSend = {
			...data,
			gender: matchGender(data.gender),
			personType: 'legal',
		}

		dispatch(registerProfileOwnerWithUser(dataToSend))
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={10} style={{ margin: '0 auto' }}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<PersonAdd />
						</CardIcon>
						<h4 style={{ color: '#999' }}>Registrar Persona Jurídica para ShareHolders</h4>
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={12}>
								<h4 className={classes.fontTitles}>
									Con este formulario usted creará un usuario para el portal y un perfil propietario de tipo{' '}
									<b>Persona Jurídica</b> con los mismos datos.
								</h4>
							</GridItem>
						</GridContainer>
						<form autoComplete='off' onSubmit={registerInvestorHandler}>
							<GridContainer className={classes.formContainer}>
								<GridItem xs={12}>
									<h4 className={classes.companyInformation}>Introducir información del usuario</h4>
								</GridItem>
								<GridItem xs={12}>
									<div className={classes.formSectionContainer}>
										<InputsUser userInfo={data} setUserInfo={setData} />
										<GridContainer>
											<SelectInputGender userInfo={data} setUserInfo={setData} update={true} />
										</GridContainer>
									</div>
								</GridItem>
								<GridItem xs={12}>
									<h4 className={classes.companyInformation}>Introducir información de la empresa</h4>
								</GridItem>
								<GridItem xs={12}>
									<div className={classes.formSectionContainer}>
										<InputsLegalProfile userInfo={data} setUserInfo={setData} />
									</div>
								</GridItem>
								{errorOwnerProfileWithUserRegister && (
									<GridItem xs={12}>
										<SnackbarContent message={errorOwnerProfileWithUserRegister} color='danger' />
									</GridItem>
								)}
								{errorGender && !data.gender && (
									<GridItem xs={12}>
										<SnackbarContent message={errorGender} color='danger' />
									</GridItem>
								)}
								<GridItem xs={12}>
									<Button
										disabled={loadingOwnerProfileWithUserRegister}
										type='submit'
										color={successOwnerProfileWithUserRegister ? `success` : 'primary'}
										block
									>
										{!successOwnerProfileWithUserRegister
											? loadingOwnerProfileWithUserRegister
												? 'Registrando...'
												: `Registrar Persona Jurídica`
											: 'Registrado Correctamente'}
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</CardBody>
				</Card>
			</GridItem>
			{alert}
		</GridContainer>
	)
}

export default OwnersUserRegisterScreen
