import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { format } from 'date-fns'
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Loader from 'components/Loader/Loader'
import ReInvestTable from './ReInvestTable'
import {
	replaceStringToNum,
	matchCurrencyToDecimal,
	matchNumberToCurrency,
} from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import amountInWords from 'shared/amountInWords/amountInWords'
import { getAllProjectsToInvest } from 'local_redux/actions/projectAction'
import { reInvestmentRegister } from 'local_redux/actions/investmentActions'
import { PROJECT_TO_INVEST_LIST_RESET } from 'local_redux/constants/projectConstant'
import { RE_INVESTMENT_REGISTER_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

let projectData = []

const ReinvestmentModalv2 = ({
	openReinvestmentModal,
	setOpenReinvestmentModal,
	handleCloseReinvestAdminModal,
	showReinvestAdminInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projectdata, setProjectData] = useState([])
	const [amountToReinvest, setAmountToReinvest] = useState([])
	const [pendingReinvestmentAmount, setPendingReinvestmentAmount] = useState([])
	const [alert, setAlert] = useState(null)
	const [error, setError] = useState({ message: '', show: false })

	const { loadingProjectList, projectListData, successProjectList } = useSelector((state) => state.projectListToInvest)
	const { reinvestmentRegisterSuccess, loadingReinvestmentRegister } = useSelector(
		(state) => state.reInvestmentRegister,
	)

	useEffect(() => {
		if (reinvestmentRegisterSuccess) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>Reinversión Registrada Correctamente</div>
				</SweetAlert>,
			)
		}
	}, [reinvestmentRegisterSuccess])
	useEffect(() => {
		if (!successProjectList) {
			dispatch(getAllProjectsToInvest(0))
		}
	}, [successProjectList])
	useEffect(() => {
		handleParams()
		return () => {
			projectData = []
		}
	}, [])
	useEffect(() => {
		if (successProjectList && showReinvestAdminInfo) {
			const data = projectListData?.projects?.map((item) => {
				let total = 0
				item?.investments?.forEach((investment) => {
					total = total + parseFloat(replaceStringToNum(investment.amount))
				})
				const amountInvertible = matchCurrencyToDecimal(item?.maxInvertible) - total
				// Change to let invest in projects that have amountInvertible less than toPay cause you can always
				// invest an small part in one project and the other part in another project
				// if (Number(matchCurrencyToDecimal(showReinvestAdminInfo?.toPay?.toString())) <= amountInvertible) {
				if (amountInvertible > 0) {
					item.view = true
					item.amountInvertible = amountInvertible
				} else {
					item.view = false
				}
				return item
			})
			setProjectData(data.filter((project) => project.view === true && project.invertible === true))
		}
	}, [successProjectList, showReinvestAdminInfo])
	useEffect(() => {
		if (pendingReinvestmentAmount < 0 && error.show === false) {
			setError({
				message: `El monto maximo de reinversion disponible es ${matchNumberToCurrency(amountToReinvest)}`,
				show: true,
			})
		}
	}, [error])

	const confirmSuccess = () => {
		setProjectData([])
		setAlert(null)
		setOpenReinvestmentModal(false)
		dispatch({ type: PROJECT_TO_INVEST_LIST_RESET })
		dispatch({ type: RE_INVESTMENT_REGISTER_RESET })
	}
	const handleParams = () => {
		const totalAmountInvested = projectData.reduce(
			(previousValue, currentValue) =>
				previousValue + Number(matchCurrencyToDecimal(currentValue.reinvestmentAmount.toString())),
			0,
		)
		const reinvestingAmount = matchCurrencyToDecimal(
			showReinvestAdminInfo.liquidation === 'benefits-only'
				? showReinvestAdminInfo.amount.toString()
				: showReinvestAdminInfo.toPay.toString(),
		)

		setAmountToReinvest(reinvestingAmount)
		setPendingReinvestmentAmount(reinvestingAmount - totalAmountInvested.toFixed(2))
	}
	const handleChange = (values, item) => {
		const project = projectData.filter((project) => project._id !== item._id)
		projectData = [...project, { ...item, reinvestmentAmount: values.value }]
		handleParams()
		if (values.value > item.amountInvertible) {
			setError({
				message: `El monto maximo invertible es ${matchNumberToCurrency(item.amountInvertible)}`,
				show: true,
			})
		} else {
			setError({
				messaje: ``,
				show: false,
			})
		}
	}
	const handleInvestmentData = () => {
		if (!error.show) {
			if (pendingReinvestmentAmount > 0) {
				return setError({
					message: `Faltan ${matchNumberToCurrency(pendingReinvestmentAmount)} por invertir`,
					show: true,
				})
			}
			const reinvestments = []
			projectData.forEach((project) => {
				const amount = matchCurrencyToDecimal(project.reinvestmentAmount.toString())
				const isValidAmount = Number(project?.reinvestmentAmount || 0) > 0
				if (isValidAmount) {
					reinvestments.push({
						projectId: project._id,
						amount: amount,
						amountInWords: amountInWords(matchCurrencyToDecimal(project.reinvestmentAmount.toString()), 'EUROS'),
						profileId: showReinvestAdminInfo.investorProfile._id,
						incomeRecivedDate: format(new Date(showReinvestAdminInfo.finishedProject[0].createdAt), 'yyyy-MM-dd'),
					})
				}
			})
			const projectsToReinvest = {
				investmentId: showReinvestAdminInfo._id,
				reinvestments,
			}
			dispatch(reInvestmentRegister(projectsToReinvest))
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openReinvestmentModal}
			keepMounted
			onClose={handleCloseReinvestAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseReinvestAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				Reinvertir {showReinvestAdminInfo?.liquidation === 'benefits-only' ? ' Importe Inicial' : 'todo'}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{loadingProjectList ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader
								message={'Cargando proyecto'}
								config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					</GridContainer>
				) : (
					<>
						<div>
							<span className={classes.spanTitle}>€ A Reinvertir:</span>
							<span className={classes.spanTitle}>
								<b>{matchNumberToCurrency(amountToReinvest)}</b>
							</span>
						</div>
						<div>
							<span className={classes.spanTitle}>€ Pendientes de Reinvertir:</span>
							<span className={`${classes.spanTitle} ${pendingReinvestmentAmount < 0 ? classes.spanError : ''}`}>
								<b>{matchNumberToCurrency(pendingReinvestmentAmount)}</b>
							</span>
						</div>
						<ReInvestTable data={projectdata} projectData={projectData} handleChange={handleChange} />
					</>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					{error.show && (
						<GridItem xs={12}>
							<SnackbarContent sx={{ width: 10 }} message={error.message} color='warning' />
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button
							color={reinvestmentRegisterSuccess ? 'success' : 'primary'}
							onClick={handleInvestmentData}
							disabled={error.show || loadingReinvestmentRegister}
							block
						>
							{loadingReinvestmentRegister ? 'Reinvirtiendo...' : 'Reinvertir'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default ReinvestmentModalv2
