import { useState } from 'react'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import styles from '../styles/confirmUpdateFinishedDateStyles'

const useStyles = makeStyles(styles)

const ConfirmUpdateFinishedDateModal = ({
  handleCloseConfirmUpdateFinishedDateModal,
  confirmUpdateFinishedModal,
  data,
  setData,
  confirmUpdateFinishedDate,
  setFinishDateUpdated,
}) => {
  const classes = useStyles()
  const [errorData, setErrordata] = useState('')

  const handleData = (e) => {
    e.preventDefault()
  }
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={confirmUpdateFinishedModal}
      keepMounted
      onClose={handleCloseConfirmUpdateFinishedDateModal}
      aria-labelledby='confirm-update-finished-date-title'
      aria-describedby='confirm-update-finished-date-description'
    >
      <DialogTitle id='confirm-update-finished-date-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseConfirmUpdateFinishedDateModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Confirmar Fecha de Fin Actualizada</h4>
      </DialogTitle>

      <DialogContent id='confirm-update-finished-date-description' className={classes.modalBody}>
        <form onSubmit={handleData} id='handle-finished-date'>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                Debe ingresar un motivo por el cual se está cambiando la fecha de fin del proyecto.
              </Typography>
              <Typography>Este cambio se le va a notificar a los inversores, ¿Esta seguro?</Typography>
            </GridItem>
            <GridItem xs={12} className={classes.input}>
              <CustomInput
                labelText='Motivo *'
                id='reason'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data.reason,
                  onChange: (e) => setData({ ...data, reason: e.target.value }),
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Typography className={classes.remember}>
                Recuerde presionar el botón de ACTUALIZAR PROYECTO al cerrar esta ventana para completar la operación.
              </Typography>
            </GridItem>
          </GridContainer>

          {errorData && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorData} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleCloseConfirmUpdateFinishedDateModal} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              block
              type='submit'
              color='primary'
              disabled={!data.reason}
              onClick={() => {
                // if (!data.reason) {
                //   return setErrordata('Debe ingresar al menos un motivo.')
                // }
                // setErrordata('')
                setFinishDateUpdated(confirmUpdateFinishedDate)
                handleCloseConfirmUpdateFinishedDateModal()
              }}
            >
              Confirmar
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmUpdateFinishedDateModal
