import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import NotesModal from 'components/CustomModals/NotesModal/NotesModal'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import DeleteUserInvestorModal from './components/InvestorUserDeleteModal'
import InvestorUserDetail from './components/InvestorUserAndProfilesDetail'
import { investorUsersListMapper } from './mappers/investor-users-list-mapper'
import { investorUsersListColumns } from './helpers/investor-user-list-columns'
import { INVESTOR_LIST_RESET } from 'local_redux/constants/investorConstant'
import { userUpdateInfo } from 'local_redux/actions/userActions'
import { getAllUsersInvestors } from 'local_redux/actions/investorAction'
import styles from './styles/investorUsersListStyle'

const useStyles = makeStyles(styles)

const InvestorUsersListScreen = () => {
	const search = useLocation().search
	const investorIdQuery = new URLSearchParams(search).get('investorId')
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const tableId = 1

	const [data, setData] = useState([])
	const [deleteUserModal, setDeleteUserModal] = useState(false)
	const [showDeleteUserInfo, setShowDeleteUserInfo] = useState({})
	const [investorUsers, setInvestorUsers] = useState([])
	const [idInvestorUser, setIdInvestorUser] = useState('')
	const [showModal, setShowModal] = useState(false)

	const { loadingInvestorGetUsers, investorGetUsersData, successInvestorGetUsers, errorInvestorGetUsersData } =
		useSelector((state) => state.investorGetUsers)
	const { successUserUpdate, userUpdated, loadingUserUpdate } = useSelector((state) => state.userUpdate)
	const { successUserDelete } = useSelector((state) => state.userDelete)

	useEffect(() => {
		dispatch(getAllUsersInvestors())
	}, [])
	useEffect(() => {
		if (successInvestorGetUsers) {
			setInvestorUsers(investorGetUsersData)
		}
	}, [successInvestorGetUsers])
	useEffect(() => {
		if (investorUsers) {
			const investorList = investorUsersListMapper(
				investorUsers,
				handleDeployNotesModal,
				showDeleteInfoHandler,
				setIdInvestorUser,
				handleNavigation,
				handleStatusUser,
				loadingUserUpdate,
				classes,
			)

			setData(investorList)
		}
	}, [setData, setIdInvestorUser, investorUsers])
	useEffect(() => {
		if (successUserUpdate) {
			const userUpdate = investorUsers.map((user) => {
				if (user._id === userUpdated._id) {
					user.email = userUpdated.email
					user.name = userUpdated.name
					user.lastName = userUpdated.lastName
					user.status = userUpdated.status
				}
				return user
			})
			setInvestorUsers(userUpdate)
		}
		if (successUserDelete) {
			if (idInvestorUser) {
				const investor = investorUsers.filter((data) => data._id !== idInvestorUser)
				setInvestorUsers(investor)
			}
		}
	}, [successUserDelete, setInvestorUsers, successUserUpdate])
	useEffect(() => {
		return () => dispatch({ type: INVESTOR_LIST_RESET })
	}, [dispatch])

	const showDeleteInfoHandler = (id) => {
		const user = investorGetUsersData.find((user) => user._id === id)
		setShowDeleteUserInfo(user)
		setDeleteUserModal(true)
	}
	const handleCloseDeleteUserModal = () => {
		setDeleteUserModal(false)
		setShowDeleteUserInfo({})
	}
	const handleDeployNotesModal = (user) => {
		setShowModal(user)
	}
	const handleNavigation = (url) => {
		navigate(url)
	}
	const handleStatusUser = (userInfo) => {
		const { status, email, _id } = userInfo
		dispatch(userUpdateInfo({ status, email, _id }))
	}

	return (
		<>
			{showModal && (
				<NotesModal
					type='user'
					open={showModal}
					handleCloseModal={() => setShowModal(false)}
					modalTitle={'Notas del usuario inversor'}
				/>
			)}
			{investorIdQuery ? (
				<InvestorUserDetail />
			) : (
				<GridContainer>
					<GridItem xs={12} className={classes.addButtonContainer}>
						<Button color='primary' onClick={() => handleNavigation('/admin/investor-register-user')}>
							Nuevo Inversor
						</Button>
					</GridItem>
					<GridItem xs={12} className={classes.rootItem}>
						<Card>
							<CardHeader color='primary' icon>
								<CardIcon color='primary'>
									<Assignment />
								</CardIcon>
								<h4 className={classes.cardIconTitle}>Inversores</h4>
							</CardHeader>
							<CardBody>
								<ReactVirtualizedTable
									tableId={tableId}
									columns={investorUsersListColumns}
									data={data}
									loading={Boolean(loadingInvestorGetUsers)}
									success={Boolean(successInvestorGetUsers)}
									error={errorInvestorGetUsersData}
									emptyText={'No se encontraron inversores.'}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			)}
			{deleteUserModal && (
				<DeleteUserInvestorModal
					handleCloseDeleteUserModal={handleCloseDeleteUserModal}
					deleteUserModal={deleteUserModal}
					showDeleteUserInfo={showDeleteUserInfo}
				/>
			)}
		</>
	)
}

export default InvestorUsersListScreen
