import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alarm } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import UpdateFinishedDateModal from './components/UpdateFinishedDateModal'
import ReasonsFinishedDateUpdateModal from './components/ReasonsFinishDateUpdatedModal'
import delayedProjectsColumns from './helpers/delayed-project-columns'
import delayedProjectsMapper from './mappers/delayed-project-mapper'
import { closeModal } from 'shared/helpers/modal-helper'
import { openModal } from 'shared/helpers/modal-helper'
import { getDelayedProjects } from 'local_redux/actions/projectAction'
import { PROJECT_DELAYED_LIST_RESET } from 'local_redux/constants/projectConstant'
import styles from './styles/delayedProjectStyles'

const useStyles = makeStyles(styles)

const DelayedProjectsScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [data, setData] = useState([])
	const [delayedProjects, setDelayedProjects] = useState([])
	const [delayedModal, setDelayedModal] = useState(false)
	const [delayedModalInfo, setDelayedModalInfo] = useState({})
	const [reasonsFinishedDateUpdateModal, setReasonsFinishedDateUpdateModal] = useState(false)
	const [reasonsFinishedDateUpdateModalInfo, setReasonsFinishedDateUpdateModalInfo] = useState({})

	const { loadingProjectsDelayedList, projectsDelayedListData, successProjectsDelayedList, errorProjectsDelayedList } =
		useSelector(({ projectsDelayedList }) => projectsDelayedList)
	const { projectsUpdateFinishedDateData } = useSelector(({ projectsUpdateFinishedDate }) => projectsUpdateFinishedDate)

	useEffect(() => {
		if (!successProjectsDelayedList) {
			dispatch(getDelayedProjects())
		}
	}, [successProjectsDelayedList])
	useEffect(() => {
		if (successProjectsDelayedList) {
			setDelayedProjects(projectsDelayedListData)
		}
	}, [successProjectsDelayedList])
	useEffect(() => {
		if (delayedProjects.length > 0) {
			setData(delayedProjectsMapper(delayedProjects))
		}
	}, [delayedProjects])
	useEffect(() => {
		let timeOut
		if (projectsUpdateFinishedDateData) {
			const updateList = delayedProjects.map((item) => {
				if (item._id === projectsUpdateFinishedDateData.project) {
					item.updateFinishedDateReasons = [
						item.updateFinishedDateReasons,
						{
							...projectsUpdateFinishedDateData,
							user: projectsUpdateFinishedDateData.user._id,
						},
					]
						.flat(Infinity)
						.filter((project) => project !== undefined)
					item.user = [item.user, projectsUpdateFinishedDateData.user]
						.flat(Infinity)
						.filter((user) => user !== undefined)
				}
				return item
			})
			setDelayedProjects(updateList)
			timeOut = setTimeout(() => {
				handleCloseUpdateFinishedDateModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [projectsUpdateFinishedDateData])
	useEffect(() => {
		return () => dispatch({ type: PROJECT_DELAYED_LIST_RESET })
	}, [dispatch])

	const handleCloseUpdateFinishedDateModal = () => {
		closeModal(setDelayedModalInfo, setDelayedModal)
	}
	const handleCloseReasonsFinishedDateUpdateModal = () => {
		closeModal(setReasonsFinishedDateUpdateModalInfo, setReasonsFinishedDateUpdateModal)
	}

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color='primary' icon>
					<CardIcon color='primary'>
						<Alarm />
					</CardIcon>
					<h4 className={classes.title}>Proyectos Retrasados</h4>
				</CardHeader>
				<CardBody>
					<ReactVirtualizedTable
						data={data}
						columns={delayedProjectsColumns({
							setDelayedModal,
							setDelayedModalInfo,
							openModal,
							setReasonsFinishedDateUpdateModalInfo,
							setReasonsFinishedDateUpdateModal,
						})}
						loading={Boolean(loadingProjectsDelayedList)}
						success={Boolean(successProjectsDelayedList)}
						error={errorProjectsDelayedList}
					/>
				</CardBody>
			</Card>
			{delayedModal && (
				<UpdateFinishedDateModal
					updateFinishedDateModal={delayedModal}
					handleCloseUpdateFinishedDateModal={handleCloseUpdateFinishedDateModal}
					showUpdateFinishedDateInfo={delayedModalInfo}
				/>
			)}
			{reasonsFinishedDateUpdateModal && (
				<ReasonsFinishedDateUpdateModal
					reasonFinishedDateUpdateInfo={reasonsFinishedDateUpdateModalInfo}
					handleCloseReasonsFinishedDateUpdateModal={handleCloseReasonsFinishedDateUpdateModal}
					reasonFinishedDateUpdateModal={reasonsFinishedDateUpdateModal}
				/>
			)}
		</GridItem>
	)
}

export default DelayedProjectsScreen
