import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardActions, makeStyles } from '@material-ui/core'
import { Assignment, Search } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import DetailInvestmentAdminModal from 'components/DetailInvestmentAdminModal/DetailInvestmentAdminModal'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import InvestmentAdminDeleteModal from './components/InvestmentAdminDeleteModal'
import SubComponentsPikers from './components/IncomeConfirmationModal'
import DeleteIncomeDateModal from './components/DeleteIncomeDateModal'
import UploadContractModal from './components/UploadContractModal'
import SignedModal from './components/SignedModal'
import ProfileControlInputs from './components/ProfileControlInputs'
import { excelMapper, investmentByInvestorMapper } from './mappers/investmentByInvestorMapper'
import { investmentByInvestorColumn } from './helpers/investmentByInvestorColumn'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import { registerLog } from 'local_redux/actions/logActions'
import { getInvestmentsByStatusAndProfile } from 'local_redux/actions/investmentActions'
import { getInvestorsProfileList } from 'local_redux/actions/investorProfileActions'
import { INVESTMENTS_BY_STATUS_AND_PROFILE_RESET } from 'local_redux/constants/investmentConstants'
import styles from './styles/investmentListScreenStyles'

const useStyles = makeStyles(styles)

const InvestmentByInvestorScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const tableId = 66

	const [data, setData] = useState([])
	const [detailInvestmentAdminModal, setDetailInvestmentAdminModal] = useState(false)
	const [selectedProfileId, setSelectedProfileId] = useState('')
	const [investmentStatus, setInvestmentStatus] = useState('')
	const [showDetailInvestmentAdminInfo, setShowDetailInvestmentAdminInfo] = useState({})
	const [deleteInvestmentAdminModal, setDeleteInvestmentAdminModal] = useState(false)
	const [showDeleteInvestmentInfo, setShowDeleteInvestmentInfo] = useState({})
	const [uploadContractFileAdminModal, setUploadContractFileAdminModal] = useState(false)
	const [showUploadContractFileInfo, setShowUploadContractFileInfo] = useState({})
	const [deleteInvestmentDateAdminModal, setDeleteInvestmentDateAdminModal] = useState(false)
	const [showDeleteInvestmentDateInfo, setShowDeleteInvestmentDateInfo] = useState({})
	const [incomeUpdateAdminModal, setIncomeUpdateAdminModal] = useState(false)
	const [showIncomeUpdateInfo, setShowIncomeUpdateInfo] = useState({})
	const [signedAdminModal, setSignedAdminModal] = useState(false)
	const [showSignedInfo, setShowSignedInfo] = useState({})
	const [investmentAdmin, setInvestmentAdmin] = useState([])
	const [idInvestment, setIdInvestment] = useState('')

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext) //context of table

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successInvestmentDeclineBySuperAdmin } = useSelector((state) => state.investmentDeclineBySuperAdmin)
	const { successIncomeUpdate, incomeUpdateData, errorIncomeUpdate } = useSelector((state) => state.incomeUpdate)
	const { successInvestmenUploadContract, investmenUploadContractData } = useSelector(
		(state) => state.investmenUploadContract,
	)
	const { successInvestmenSignContract, investmenSignContractData } = useSelector(
		(state) => state.investmenSignContract,
	)
	const { successIncomeDeleteDate, incomeDeleteDateData } = useSelector((state) => state.incomeDeleteDate)
	const { loadingInvestmentsList, successInvestmentsList, investmentsList, errorInvestmentsList } = useSelector(
		(state) => state.investmentsByStatusAndProfile,
	)

	useEffect(() => {
		if (successInvestmentsList) {
			setInvestmentAdmin(investmentsList)
		}
	}, [successInvestmentsList])
	useEffect(() => {
		dispatch(getInvestorsProfileList())
	}, [])
	useEffect(() => {
		dispatch(getInvestmentsByStatusAndProfile(selectedProfileId, investmentStatus ? investmentStatus : null))
	}, [selectedProfileId, investmentStatus])
	useEffect(() => {
		if (Array.isArray(investmentAdmin) && investmentAdmin.length > 0 && !errorInvestmentsList) {
			const data = investmentByInvestorMapper(investmentAdmin)
			setData(data)
		} else {
			setData([])
		}
	}, [investmentAdmin, setData, setIdInvestment, errorInvestmentsList])
	useEffect(() => {
		if (successInvestmentDeclineBySuperAdmin) {
			let investment = investmentAdmin.filter((data) => data._id !== idInvestment)
			setInvestmentAdmin(investment)
		}
		if (successIncomeUpdate || successIncomeDeleteDate) {
			let incomeData = successIncomeUpdate ? incomeUpdateData : incomeDeleteDateData
			const investment = investmentAdmin.map((data) => {
				if (data._id === incomeData._id) {
					data.incomeRecivedDate = incomeData.incomeRecivedDate
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
		if (successInvestmenUploadContract) {
			dispatch(getInvestmentsByStatusAndProfile(selectedProfileId, investmentStatus ? investmentStatus : null))
			const investment = investmentAdmin.map((data) => {
				if (data._id === investmenUploadContractData._id) {
					data.contractUrl = investmenUploadContractData.contractUrl
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
		if (successInvestmenSignContract) {
			const investment = investmentAdmin.map((data) => {
				if (data._id === investmenSignContractData._id) {
					data.signed = true
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
	}, [
		setInvestmentAdmin,
		successInvestmentDeclineBySuperAdmin,
		successIncomeUpdate,
		successInvestmenUploadContract,
		successInvestmenSignContract,
		successIncomeDeleteDate,
	])
	useEffect(() => {
		return () => dispatch({ type: INVESTMENTS_BY_STATUS_AND_PROFILE_RESET })
	}, [dispatch])
	useEffect(() => {
		if (successIncomeUpdate) {
			handleIncomeLog(null, incomeUpdateData)
		}
		if (errorIncomeUpdate) {
			const investment = investmentAdmin.filter((item) => item._id === idInvestment)[0]
			handleIncomeLog(errorIncomeUpdate, investment)
		}
	}, [successIncomeUpdate, errorIncomeUpdate])

	const handleCloseDeclineAdminModal = () => {
		closeModal(setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)
	}
	const handleCloseInvestmentAdminModal = () => {
		closeModal(setShowDeleteInvestmentInfo, setDeleteInvestmentAdminModal)
	}
	const showDeleteInvestmentDateHandler = (investment) => {
		openModal(investment, setShowDeleteInvestmentDateInfo, setDeleteInvestmentDateAdminModal)
	}
	const handleCloseInvestmentDateAdminModal = () => {
		closeModal(setShowDeleteInvestmentDateInfo, setDeleteInvestmentDateAdminModal)
	}
	const showIncomeUpdateHandler = (item) => {
		openModal(item, setShowIncomeUpdateInfo, setIncomeUpdateAdminModal)
	}
	const handleCloseIncomeUpdateModal = () => {
		closeModal(setShowIncomeUpdateInfo, setIncomeUpdateAdminModal)
	}
	const handleCloseSignedModal = () => {
		closeModal(setShowSignedInfo, setSignedAdminModal)
	}
	const handleCloseUploadContractModal = () => {
		closeModal(setShowUploadContractFileInfo, setUploadContractFileAdminModal)
	}
	const handleIncomeLog = (error, data) => {
		const actionType = !error ? 'INCOME_REGISTER_SUCCESS' : 'INCOME_REGISTER_FAIL'
		const type = error ? 'error' : 'action'
		const logInfo = {
			type: {
				type,
			},
			investors: {
				id: data.investorProfile._id,
				businessName: data.investorProfile.businessName,
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			project: {
				id: data.project._id,
				projectAddress: data.project.projectAddress,
			},
		}
		if (!error) {
			logInfo.investment = {
				id: data._id,
				amount: data.amount,
			}
			logInfo.type.actionType = actionType
		} else {
			logInfo.type.error = {
				errorType: actionType,
				errorMessage: errorIncomeUpdate,
			}
		}
		dispatch(registerLog(logInfo))
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={8}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Search />
							</CardIcon>
						</CardHeader>
						<CardBody>
							<GridContainer className={classes.gridContainer}>
								<ProfileControlInputs
									selectedProfileId={selectedProfileId}
									setSelectedProfileId={setSelectedProfileId}
									setInvestmentStatus={setInvestmentStatus}
									investmentStatus={investmentStatus}
								/>
							</GridContainer>
						</CardBody>
					</Card>
				</GridItem>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Inversiones</h4>
						</CardHeader>
						<CardActions>
							<GridItem xs={12}>
								{data.length > 0 && (
									<ExcelDefaultExport
										excelName='Lista de inversiones'
										tableColumns={getFilteredDataColumns({ tableId })}
										data={excelMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
									/>
								)}
							</GridItem>
						</CardActions>
						<CardBody>
							<ReactVirtualizedTable
								columns={investmentByInvestorColumn(
									showDeleteInvestmentDateHandler,
									showIncomeUpdateHandler,
									setIdInvestment,
									userInfo,
									setShowUploadContractFileInfo,
									setUploadContractFileAdminModal,
									setShowSignedInfo,
									setSignedAdminModal,
									setShowDetailInvestmentAdminInfo,
									setDetailInvestmentAdminModal,
									setShowDeleteInvestmentInfo,
									setDeleteInvestmentAdminModal,
									classes,
								)}
								tableId={tableId}
								data={data}
								loading={Boolean(loadingInvestmentsList)}
								success={Boolean(successInvestmentsList)}
								error={selectedProfileId ? errorInvestmentsList : 'Seleccione un perfil'}
								emptyText={'No se encontraron inversiones.'}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{detailInvestmentAdminModal && (
				<DetailInvestmentAdminModal
					handleCloseDeclineAdminModal={handleCloseDeclineAdminModal}
					detailInvestmentAdminModal={detailInvestmentAdminModal}
					showDetailInvestmentAdminInfo={showDetailInvestmentAdminInfo}
				/>
			)}
			{deleteInvestmentAdminModal && (
				<InvestmentAdminDeleteModal
					handleCloseInvestmentAdminModal={handleCloseInvestmentAdminModal}
					deleteInvestmentAdminModal={deleteInvestmentAdminModal}
					showDeleteInvestmentInfo={showDeleteInvestmentInfo}
				/>
			)}
			{incomeUpdateAdminModal && (
				<SubComponentsPikers
					handleCloseIncomeUpdateModal={handleCloseIncomeUpdateModal}
					incomeUpdateModal={incomeUpdateAdminModal}
					showIncomeUpdateInfo={showIncomeUpdateInfo}
				/>
			)}
			{deleteInvestmentDateAdminModal && (
				<DeleteIncomeDateModal
					deleteInvestmentDateAdminModal={deleteInvestmentDateAdminModal}
					handleCloseInvestmentDateAdminModal={handleCloseInvestmentDateAdminModal}
					showDeleteInvestmentDateInfo={showDeleteInvestmentDateInfo}
				/>
			)}
			{uploadContractFileAdminModal && (
				<UploadContractModal
					handleCloseUploadContractModal={handleCloseUploadContractModal}
					showUploadContractInfo={showUploadContractFileInfo}
					uploadContractModal={uploadContractFileAdminModal}
				/>
			)}
			{signedAdminModal && (
				<SignedModal
					handleCloseSignedModal={handleCloseSignedModal}
					signedModal={signedAdminModal}
					showSignedInfo={showSignedInfo}
				/>
			)}
		</>
	)
}

export default InvestmentByInvestorScreen
