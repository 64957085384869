import { createRef, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import InvestorProfileDocumentsForm from 'components/InvestorProfileDocumentsForm/InvestorProfileDocumentsForm'
import Clearfix from 'components/Clearfix/Clearfix'
import InputsLegalPerson from './InputsLegalPerson'
import InputsPhysicalPerson from './InputsPhysicalPerson'
import { matchGender } from 'shared/matchData/matchData'
import { updateInvestorProfile } from 'local_redux/actions/investorProfileActions'
import {
	INVESTORS_PROFILE_BY_USER_RESET,
	INVESTOR_PROFILE_UPDATE_RESET,
} from 'local_redux/constants/investorProfileConstants'
import { findDocument } from 'shared/helpers/functions-helpers'
import styles from '../styles/investorProfileUpdateModalStyles'

const useStyles = makeStyles(styles)

const InvestorProfileUpdateModal = ({
	handleCloseInvestorUpdateModal,
	investorProfileModal,
	showInvestorUpdateInfo,
	setErrorTaxResidenceFile,
}) => {
	const fileInput = createRef()
	const dispatch = useDispatch()
	const classes = useStyles()
	const isLegal = showInvestorUpdateInfo.personType === 'legal'

	const [infoModalData, setModalData] = useState(showInvestorUpdateInfo)
	const [errorImage, setErrorImage] = useState('')
	const [imagesUrl, setImagesUrl] = useState()
	const [profileDniFront, setProfileDniFront] = useState('')
	const [profileDniBack, setProfileDniBack] = useState('')
	const [kycContract, setKycContract] = useState('')
	const [accountOwnership, setAccountOwnership] = useState('')

	const { loadingInvestorProfileUpdate, errorInvestorProfileUpdate, successInvestorProfileUpdate } = useSelector(
		(state) => state.investorProfileUpdate,
	)

	useEffect(() => {
		setModalData({
			...showInvestorUpdateInfo,
			location: showInvestorUpdateInfo.location || 'Madrid',
			province: showInvestorUpdateInfo.province || 'Madrid',
			postalCode: showInvestorUpdateInfo.postalCode || 28001,
		})

		setProfileDniFront(findDocument(showInvestorUpdateInfo, 'profileDniFront'))
		setProfileDniBack(findDocument(showInvestorUpdateInfo, 'profileDniBack'))
		setKycContract(findDocument(showInvestorUpdateInfo, 'kycContract'))
		setAccountOwnership(findDocument(showInvestorUpdateInfo, 'accountOwnership'))
	}, [])
	useEffect(() => {
		let timeOut = ''
		if (successInvestorProfileUpdate) {
			timeOut = setTimeout(() => {
				dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
				dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
				handleCloseInvestorUpdateModal()
			}, 2000)
		}

		return () => clearTimeout(timeOut)
	}, [successInvestorProfileUpdate])
	useEffect(() => {
		return () => dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
	}, [dispatch])
	useEffect(() => {
		setImagesUrl(infoModalData.logo)
	}, [])

	const updateInvestorProfileHandler = useCallback(
		(e) => {
			e.preventDefault()
			if (!infoModalData.postalCode.length === 5) {
				return
			}
			const matchData = {
				...infoModalData,
				gender: matchGender(infoModalData.gender),
				profileDniFront: typeof profileDniFront !== 'object' ? '' : profileDniFront[0],
				profileDniBack: typeof profileDniBack !== 'object' ? '' : profileDniBack[0],
				kycContract: typeof kycContract !== 'object' ? '' : kycContract[0],
				accountOwnership: typeof accountOwnership !== 'object' ? '' : accountOwnership[0],
				filesToDelete: {
					profileDniFront: profileDniFront === undefined,
					profileDniBack: profileDniBack === undefined,
					kycContract: kycContract === undefined,
					accountOwnership: accountOwnership === undefined,
				},
			}

			dispatch(updateInvestorProfile(matchData))
		},
		[profileDniBack, profileDniFront, kycContract, accountOwnership, infoModalData],
	)

	const imagesValidation = imagesUrl || infoModalData.logo
	const selectImageHandler = () => {
		fileInput.current.click()
	}
	const handleImageChange = (e) => {
		e.preventDefault()

		let imageFiles = e.target.files[0]

		const imagesURL = {
			name: imageFiles.name,
			url: URL.createObjectURL(imageFiles),
		}
		setModalData({ ...infoModalData, logo: imageFiles })
		setImagesUrl(imagesURL)
	}
	const handleRemoveImage = () => {
		setModalData({ ...infoModalData, logo: '' })
		setImagesUrl('')
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={investorProfileModal}
			keepMounted
			onClose={handleCloseInvestorUpdateModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseInvestorUpdateModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4 className={classes.modalTitle}>Editar Perfil</h4>
			</DialogTitle>

			<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
				<form onSubmit={updateInvestorProfileHandler} id='investor-profile-update-form'>
					{isLegal ? (
						<InputsLegalPerson
							userInfo={infoModalData}
							setUserInfo={setModalData}
							setErrorTaxResidenceFile={setErrorTaxResidenceFile}
						/>
					) : (
						<InputsPhysicalPerson
							userInfo={infoModalData}
							setUserInfo={setModalData}
							setErrorTaxResidenceFile={setErrorTaxResidenceFile}
						/>
					)}
					<GridItem xs={12} className={classes.filesContainer}>
						<InvestorProfileDocumentsForm
							showInvestorProfileUploadDocumentInfo={infoModalData}
							setInvestorProfileUploadDocumentInfo={setModalData}
							profileDniFront={profileDniFront}
							setProfileDniFront={setProfileDniFront}
							profileDniBack={profileDniBack}
							setProfileDniBack={setProfileDniBack}
							kycContract={kycContract}
							setKycContract={setKycContract}
							accountOwnership={accountOwnership}
							setAccountOwnership={setAccountOwnership}
						/>
					</GridItem>
					<GridContainer spacing={3}>
						{!imagesValidation && (
							<GridItem xs={12} className={classes.addPhotoRoot}>
								<div className='fileinput'>
									<input
										type='file'
										name='images'
										accept='.png, .jpg, .jpeg'
										capture='camera'
										id='project-camera'
										multiple
										onChange={handleImageChange}
										ref={fileInput}
									/>
								</div>
								<Button color='primary' size='lg' justIcon round onClick={() => selectImageHandler()}>
									Logo
								</Button>
							</GridItem>
						)}
						{errorImage && !imagesValidation && (
							<GridItem xs={12} sm={12}>
								<SnackbarContent message={errorImage} color='danger' />
							</GridItem>
						)}
						{errorInvestorProfileUpdate && (
							<GridItem xs={12} sm={12}>
								<SnackbarContent message={errorInvestorProfileUpdate} color='danger' />
							</GridItem>
						)}
					</GridContainer>
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button
							type='submit'
							color={successInvestorProfileUpdate ? 'success' : 'primary'}
							block
							form='investor-profile-update-form'
						>
							{loadingInvestorProfileUpdate
								? 'Actualizando'
								: successInvestorProfileUpdate
								? 'Perfil Actualizado'
								: 'Actualizar Perfil'}
						</Button>
						<Clearfix />
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default InvestorProfileUpdateModal
