import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Switch,
	makeStyles,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Loader from 'components/Loader/Loader'
import CustomInput from 'components/CustomInput/CustomInput'
import ReInvestPDLTable from './ReInvestPDLTable'
import { validationInfoTemplate } from 'shared/helpers/validation-templates'
import { matchCurrencyToDecimal, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { registerReinvestPdl } from 'local_redux/actions/projectLiquidityActions'
import { getProjectsWithPDLToInvest } from 'local_redux/actions/projectAction'
import { getProfilesForPDL } from 'local_redux/actions/investorProfileActions'
import { PROJECT_PDL_TO_INVEST_LIST_RESET } from 'local_redux/constants/projectConstant'
import { INVESTORS_PROFILE_LIST_FOR_PDL_RESET } from 'local_redux/constants/investorProfileConstants'
import { PDL_REGISTER_REINVESTMENT_RESET } from 'local_redux/constants/projectLiquidityConstants'
import styles from '../styles/reinvestmentModalStyles'

const useStyles = makeStyles(styles)

const ReInvestmentPDLModalPdlScreen = ({
	openReinvestmentModal,
	setOpenReinvestmentModal,
	handleCloseReinvestAdminModal,
	showReinvestAdminInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projectdata, setProjectData] = useState([])
	const [changeProjectData, setChangeProjectData] = useState([])
	const [amountToReinvest, setAmountToReinvest] = useState([])
	const [pendingReinvestmentAmount, setPendingReinvestmentAmount] = useState([])
	const [alert, setAlert] = useState(null)
	const [error, setError] = useState({ message: '', show: false })
	const [sendContract, setSendContract] = useState(true)
	const [projectAddressToFilter, setProjectAddressToFilter] = useState('')
	const [filteredProjects, setFilteredProjects] = useState([])

	const { successPdlRegisterReinvestment, loadingPdlRegisterReinvestment } = useSelector(
		(state) => state.pdlRegisterReinvestment,
	)
	const { userInfo } = useSelector((state) => state.userLogin)
	const { profilesForPDL } = useSelector((state) => state.investorsProfileListForPDL)
	const {
		loadingPDLProjectsToInvestList,
		successPDLProjectsToInvestList,
		PDLProjectsToInvestListData,
		errorPDLProjectsToInvestList,
	} = useSelector((state) => state.projectsWithPDLToInvest)

	useEffect(() => {
		if (successPdlRegisterReinvestment) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'center' }}>PDL registrado con éxito</div>
				</SweetAlert>,
			)
		}
	}, [successPdlRegisterReinvestment])
	useEffect(() => {
		if (!successPDLProjectsToInvestList) {
			dispatch(getProjectsWithPDLToInvest())
			dispatch(getProfilesForPDL())
		}
	}, [successPDLProjectsToInvestList])
	useEffect(() => {
		handleParams([])
	}, [])
	useEffect(() => {
		if (successPDLProjectsToInvestList && showReinvestAdminInfo) {
			const data = PDLProjectsToInvestListData?.map((item) => {
				if (item?.pdlAvailable > 0) {
					item.view = true
					item.amountInvertible = item?.pdlAvailable
					item.invertible = true
				} else {
					item.view = false
				}
				return item
			})
			setProjectData(data.filter((project) => project.view === true && project.invertible === true))
		}
	}, [successPDLProjectsToInvestList, showReinvestAdminInfo])
	useEffect(() => {
		if (pendingReinvestmentAmount < 0 && error.show === false) {
			setError({
				message: `El monto maximo de reinversion disponible es ${matchNumberToCurrency(amountToReinvest)}`,
				show: true,
			})
		}
	}, [error])
	useEffect(() => {
		setFilteredProjects(
			projectdata.filter((project) =>
				project?.projectAddress?.toLowerCase().includes(projectAddressToFilter.toLowerCase()),
			),
		)
	}, [projectAddressToFilter, projectdata])

	const confirmSuccess = () => {
		setProjectData([])
		setAlert(null)
		setOpenReinvestmentModal(false)
		dispatch({ type: PROJECT_PDL_TO_INVEST_LIST_RESET })
		dispatch({ type: INVESTORS_PROFILE_LIST_FOR_PDL_RESET })
		dispatch({ type: PDL_REGISTER_REINVESTMENT_RESET })
	}
	const handleParams = (data) => {
		const totalAmountInvested = data?.reduce(
			(previousValue, currentValue) =>
				previousValue + Number(matchCurrencyToDecimal(currentValue.reinvestmentAmount.toString())),
			0,
		)
		const reinvestingAmount = matchCurrencyToDecimal(
			showReinvestAdminInfo.liquidationFields?.liquidation === 'benefits-only'
				? showReinvestAdminInfo.amountPdl?.toString()
				: showReinvestAdminInfo.liquidationFields.toPay?.toString(),
		)

		setAmountToReinvest(Number(reinvestingAmount).toFixed(2))
		setPendingReinvestmentAmount(Number(reinvestingAmount).toFixed(2) - totalAmountInvested.toFixed(2))
	}
	const handleChange = (values, item) => {
		const projectExists = changeProjectData.some((project) => project._id === item._id)

		const updateProject = changeProjectData.map((project) => {
			if (project._id === item._id) {
				return { ...project, reinvestmentAmount: values.reinvestmentAmount, typeOfInterest: values.typeOfInterest }
			}
			return project
		})

		if (!projectExists) {
			updateProject.push({
				...item,
				reinvestmentAmount: values.reinvestmentAmount,
				typeOfInterest: values.typeOfInterest,
			})
		}

		setChangeProjectData(updateProject)

		handleParams(updateProject)
		if (values.value > item.amountInvertible) {
			setError({
				message: `El monto maximo invertible es ${matchNumberToCurrency(item.amountInvertible)}`,
				show: true,
			})
		} else {
			setError({
				messaje: ``,
				show: false,
			})
		}
	}
	const handleDateReinvest = (value, item) => {
		const projectExists = changeProjectData.some((project) => project._id === item._id)

		const updateProject = changeProjectData.map((project) => {
			if (project._id === item._id) {
				return { ...project, dateReinvest: value }
			}
			return project
		})

		if (!projectExists) {
			updateProject.push({ ...item, dateReinvest: value })
		}

		setChangeProjectData(updateProject)
	}
	const handleInvestmentData = () => {
		if (!error.show) {
			if (parseInt(pendingReinvestmentAmount) > 0) {
				return setError({
					message: `Faltan ${matchNumberToCurrency(pendingReinvestmentAmount)} por invertir`,
					show: true,
				})
			}
			const profileInvestor = profilesForPDL?.find((e) => e?._id === showReinvestAdminInfo?.profileInvestor?._id)
			const validation = profileInvestor
				? validationInfoTemplate({
						...profileInvestor,
						addressOfTheRepresentative: profileInvestor?.addressOfTheRepresentative || profileInvestor?.owner?.address,
				  })
				: false
			if (!validation.success) {
				setError({
					message: 'Faltan datos requeridos.',
					show: true,
				})
				return
			}
			const newPdls = []
			changeProjectData.forEach((project) => {
				const amountInNumb = Number(project.reinvestmentAmount)
				if (amountInNumb > 0) {
					newPdls.push({
						project: project?._id,
						profileInvestor,
						amountPdl: amountInNumb,
						projectAddress: project?.projectAddress,
						maxSaleDate: project?.maxSaleDate,
						dateReinvest: project?.dateReinvest || new Date(),
						user: userInfo?._id,
						typeOfInterest: `${parseInt(project?.typeOfInterest)}%`,
					})
				}
			})
			const bodyReinvestPdl = {
				pdlId: showReinvestAdminInfo?._id,
				sendContract,
				pdls: newPdls,
			}
			dispatch(registerReinvestPdl(bodyReinvestPdl))
			return
		}
	}
	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openReinvestmentModal}
			keepMounted
			onClose={handleCloseReinvestAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseReinvestAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				Reinvertir {showReinvestAdminInfo?.liquidation === 'benefits-only' ? ' Importe Inicial' : 'todo'}
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{loadingPDLProjectsToInvestList ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader
								message={'Cargando proyecto'}
								config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					</GridContainer>
				) : (
					<>
						<div>
							<span className={classes.spanTitle}>€ A Reinvertir:</span>
							<span className={classes.spanTitle}>
								<b>{matchNumberToCurrency(amountToReinvest)}</b>
							</span>
						</div>
						<div>
							<span className={classes.spanTitle}>€ Pendientes de Reinvertir:</span>
							<span className={`${classes.spanTitle} ${pendingReinvestmentAmount < 0 ? classes.spanError : ''}`}>
								<b>{matchNumberToCurrency(pendingReinvestmentAmount)}</b>
							</span>
						</div>
						{userInfo.isAdmin || userInfo.isSuper ? (
							<FormControlLabel
								control={
									<Switch
										checked={sendContract}
										classes={{
											switchBase: classes.switchBase,
											checked: classes.switchChecked,
											thumb: classes.switchIcon,
											track: classes.switchBar,
										}}
										onChange={(e) => setSendContract(e.target.checked)}
									/>
								}
								label='Enviar contrato'
							/>
						) : null}
						<GridContainer>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={'Buscar por dirección *'}
									id='project-address-filter'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: projectAddressToFilter,
										onChange: (e) => {
											setProjectAddressToFilter(e.target.value)
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
						<ReInvestPDLTable
							data={filteredProjects}
							projectData={changeProjectData}
							handleChange={handleChange}
							handleDateReinvest={handleDateReinvest}
						/>
					</>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					{(error.show || errorPDLProjectsToInvestList) && (
						<GridItem xs={12}>
							<SnackbarContent
								sx={{ width: 10 }}
								message={errorPDLProjectsToInvestList || error.message}
								color='warning'
							/>
						</GridItem>
					)}
					<GridItem xs={12}>
						<Button
							color={successPdlRegisterReinvestment ? 'success' : 'primary'}
							onClick={handleInvestmentData}
							disabled={error.show || loadingPdlRegisterReinvestment}
							block
						>
							{loadingPdlRegisterReinvestment ? 'Reinvirtiendo...' : 'Reinvertir'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
			{alert}
		</Dialog>
	)
}

export default ReInvestmentPDLModalPdlScreen
