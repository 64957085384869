import { useEffect, useState } from 'react'
import { CardActions, makeStyles, Typography } from '@material-ui/core'
import { FormHelperText } from '@mui/material'
import { Person, ContentCopy } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import styles from '../styles/preRegisterDetailsStyles'

const useStyles = makeStyles(styles)

const PreRegisterDetails = ({ preRegisterData, confirmSuccess }) => {
  const classes = useStyles()
  const { name, lastName, phone, url, email } = preRegisterData

  const [confirmTextInClipBoard, setConfirmTextInClipBoard] = useState('')

  useEffect(() => {
    let timeOut
    if (confirmTextInClipBoard) {
      timeOut = setTimeout(() => {
        setConfirmTextInClipBoard('')
      }, 3000)
    }
    return () => clearTimeout(timeOut)
  }, [confirmTextInClipBoard])

  const copyOnClipBoard = () => {
    navigator.clipboard.writeText(url)
    setConfirmTextInClipBoard('Link Copiado en el Portapapeles')
  }

  return (
    <GridContainer>
      <Card>
        <CardHeader color='primary' icon>
          <CardIcon color='primary'>
            <Person />
          </CardIcon>
          <h4 className={classes.cardTitle}>Inversor Pre-Registrado</h4>
        </CardHeader>
        <CardBody>
          <GridContainer className={classes.gridBody}>
            {name && (
              <GridItem xs={12} className={classes.header}>
                <h5>
                  <b>
                    {name || ''} {lastName || ''}
                  </b>
                </h5>
              </GridItem>
            )}
            {phone && (
              <GridItem xs={12} className={classes.infoRow}>
                <p>Teléfono:</p>
                <p>
                  <b>{phone || ''}</b>
                </p>
              </GridItem>
            )}
            {email && (
              <GridItem xs={12} className={classes.infoRow}>
                <p>Email:</p>
                <p>
                  <b>{email || ''}</b>
                </p>
              </GridItem>
            )}
            <GridItem xs={12} className={classes.infoRow}>
              <p>Link de Pre-Registro:</p>
              <div className={classes.clipBoardText} onClick={copyOnClipBoard}>
                <span>{url.slice(0, 90).concat('...')}</span>
                <Button color='primary' justIcon round simple className={classes.clipBoardButton}>
                  <ContentCopy />
                </Button>
              </div>
              {confirmTextInClipBoard && (
                <FormHelperText className={classes.helperText}>{confirmTextInClipBoard}</FormHelperText>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardActions>
          <Button onClick={confirmSuccess} color='primary' block>
            Cerrar
          </Button>
        </CardActions>
      </Card>
    </GridContainer>
  )
}

export default PreRegisterDetails
