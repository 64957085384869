import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { FormControlLabel, Switch, makeStyles } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import { verifyEmail, verifyLength } from 'shared/validators/inputValidators'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import UserPermissions from 'components/UserPermissions/UserPermissions'
import userPermissionsInitialState from 'config/roles/user-permissions'
import { registerUser } from 'local_redux/actions/userActions'
import { USER_REGISTER_RESET } from 'local_redux/constants/userConstants'
import styles from './styles/registerUserScreenStyle'

const useStyles = makeStyles(styles)

const RegisterUserScreen = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [registerEmailState, setRegisterEmailState] = useState('')
  const [phone, setPhone] = useState('')
  const [permissions, setPermissions] = useState(userPermissionsInitialState)
  const [sendEmailConfirmation, setSendEmailConfirmation] = useState(true)
  const [alert, setAlert] = useState(null)

  const { loadingUserRegister, successUserRegister, errorUserRegister } = useSelector((state) => state.userRegister)

  useEffect(() => {
    if (successUserRegister) {
      setAlert(
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title='¡Hecho!'
          onConfirm={() => confirmSuccess()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.confirmBtnCssClass}
        >
          Usuario Registrado Correctamente
        </SweetAlert>
      )
    }
  }, [successUserRegister])

  const registerUserHandler = (e) => {
    e.preventDefault()
    const createUser = {
      name,
      email,
      phone,
      ...permissions,
      sendEmailConfirmation,
    }
    dispatch(registerUser(createUser))
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const confirmSuccess = () => {
    setName('')
    setEmail('')
    setRegisterEmailState('')
    setPhone('')
    setPermissions(userPermissionsInitialState)
    setAlert(null)
    setSendEmailConfirmation(true)
    dispatch({ type: USER_REGISTER_RESET })
  }

  const handleIsAdmin = (e) => {
    setIsAdmin(e.target.checked)
    if (isSuper && !e.target.checked) {
      setIsSuper(false)
    }
  }

  const handleIsSuper = (e) => {
    setIsAdmin(e.target.checked)
    setIsSuper(e.target.checked)
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={10} style={{ margin: '0 auto' }}>
        <Card>
          <CardHeader color='primary' icon>
            <CardIcon color='primary'>
              <PersonAdd />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Registrar Usuario</h4>
          </CardHeader>
          <CardBody>
            <form onSubmit={registerUserHandler}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    labelText='Nombre *'
                    id='name'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: name,
                      onChange: (e) => {
                        setName(e.target.value)
                      },
                      type: 'text',
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    error={registerEmailState === 'error'}
                    labelText='Correo Electrónico *'
                    id='email'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: email,
                      onChange: (e) => {
                        if (verifyLength(email, 10)) {
                          if (verifyEmail(e.target.value)) {
                            setRegisterEmailState('')
                          } else {
                            setRegisterEmailState('error')
                          }
                        }
                        setEmail(e.target.value)
                      },
                      type: 'email',
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    labelText='Teléfono *'
                    id='phone'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: phone,
                      onChange: (e) => {
                        setPhone(e.target.value)
                      },
                      type: 'text',
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className={classes.permissionsRoot}>
                <GridItem xs={12}>
                  <UserPermissions permissions={permissions} setPermissions={setPermissions} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={sendEmailConfirmation}
                        onChange={() => setSendEmailConfirmation(!sendEmailConfirmation)}
                        value='sendEmailConfirmation'
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label='Enviar Email de Confirmación'
                  />
                </GridItem>
              </GridContainer>
              {errorUserRegister && (
                <GridContainer>
                  <GridItem xs={12}>
                    <SnackbarContent message={errorUserRegister} color='danger' />
                  </GridItem>
                </GridContainer>
              )}
              <Button type='submit' color='primary' className={classes.registerButton}>
                {loadingUserRegister ? 'Creando usuario...' : 'Registrar Usuario'}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  )
}

export default RegisterUserScreen
