import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { makeStyles, Card, CardActions, Collapse, Typography, Grid } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GridItem from 'components/Grid/GridItem'
import InvestorProfileListByUser from 'components/InvestorsProfileListByUser/InvestorProfileListByUser'
import Loader from 'components/Loader/Loader'
import InvestorUserUpdateForm from './InvestorUserUpdateForm'
import ProfileCreateModal from './ProfileCreateModal'
import { matchGender } from 'shared/matchData/matchData'
import {
	INVESTORS_PROFILE_BY_USER_RESET,
	INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET,
} from 'local_redux/constants/investorProfileConstants'
import { investorsProfileByUser, registerProfileInvestorByAdmin } from 'local_redux/actions/investorProfileActions'
import styles from '../styles/InvestorUserAndProfilesDetailStyle'

const ExpandMore = styled((props) => {
	const { expand, ...other } = props
	return <IconButton {...other} />
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}))

const useStyles = makeStyles(styles)

const InvestorUserAndProfilesDetail = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const search = useLocation().search
	const investorIdQuery = new URLSearchParams(search).get('investorId')

	const initialState = {
		name: '',
		lastName: '',
		nif: '',
		phone: '',
		email: '',
		address: '',
		province: 'Madrid',
		location: 'Madrid',
		postalCode: '28001',
		country: '',
		retention: '',
		residenceCertificate: '',
		fiscalNationality: '',
		liquidation: 'pay',
		bankAccountNumber: '',
		gender: '',
		prescriber: '',
		businessName: '',
		cif: '',
		personType: '',
		representativeName: '',
		representativeLastName: '',
		representativeNif: '',
		representativeAddress: '',
		userId: '',
		userGender: '',
		nationality: '',
		nationalityOfTheRepresentative: '',
	}
	const [data, setData] = useState(initialState)
	const [expanded, setExpanded] = useState(true)
	const [investorProfilesList, setInvestorProfilesList] = useState([])
	const [createModal, setCreateModal] = useState(false)

	const { loadingInvestorsProfileByUser, successInvestorsProfileByUser, investorsProfileByUserData } = useSelector(
		(state) => state.investorsProfileByUser,
	)
	const { successInvestorProfileByAdminRegister, investorProfileByAdminRegisterData } = useSelector(
		(state) => state.investorProfileByAdminRegister,
	)
	const { userDetail } = useSelector((state) => state.userDetails)

	useEffect(() => {
		if (!successInvestorsProfileByUser) {
			dispatch(investorsProfileByUser(investorIdQuery))
		} else {
			setInvestorProfilesList(investorsProfileByUserData)
		}
	}, [investorIdQuery, successInvestorsProfileByUser])

	useEffect(() => {
		if (successInvestorProfileByAdminRegister) {
			setInvestorProfilesList([...investorProfilesList, investorProfileByAdminRegisterData])
		}
	}, [successInvestorProfileByAdminRegister])

	useEffect(() => {
		return () => {
			dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
			dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET })
		}
	}, [dispatch])

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}
	const handleCreateProfile = (e) => {
		const matchData = {
			...data,
			userId: userDetail._id,
			userGender: userDetail.gender,
			gender: matchGender(data.gender, false),
		}
		dispatch(registerProfileInvestorByAdmin(matchData))
	}

	return (
		<>
			{loadingInvestorsProfileByUser ? (
				<GridItem className={classes.Loader}>
					<Loader />
				</GridItem>
			) : (
				investorProfilesList && (
					<>
						<GridItem xs={12}>
							<InvestorUserUpdateForm userId={investorIdQuery} />
						</GridItem>

						{investorProfilesList?.length > 0 ? (
							<Grid item xs={12} sm={10} md={9} className={classes.marginAuto}>
								<Card>
									<CardActions disableSpacing>
										<GridItem xs={11}>
											<Typography variant='h6'>Perfiles</Typography>
										</GridItem>
										<ExpandMore onClick={() => setCreateModal(!createModal)}>
											<PersonAdd />
										</ExpandMore>
										<ExpandMore
											expand={expanded}
											onClick={handleExpandClick}
											aria-expanded={expanded}
											aria-label='show more'
										>
											<ExpandMoreIcon />
										</ExpandMore>
									</CardActions>
									<Collapse in={expanded} timeout='auto' unmountOnExit>
										{expanded && <InvestorProfileListByUser profiles={investorProfilesList} />}
									</Collapse>
								</Card>
							</Grid>
						) : (
							<Grid item xs={12} sm={8} className={classes.root + ' ' + classes.marginAuto}>
								<Card>
									<CardActions disableSpacing>
										<Typography variant='h6'>Sin Perfiles </Typography>
										<ExpandMore onClick={() => setCreateModal(!createModal)}>
											<PersonAdd />
										</ExpandMore>
									</CardActions>
								</Card>
							</Grid>
						)}
					</>
				)
			)}

			{createModal && (
				<ProfileCreateModal
					setData={setData}
					data={data}
					createModal={createModal}
					handleOpenClose={() => setCreateModal(!createModal)}
					handleCreateProfile={handleCreateProfile}
					initialState={initialState}
				/>
			)}
		</>
	)
}

export default InvestorUserAndProfilesDetail
