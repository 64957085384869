import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import {
	Autocomplete,
	Backdrop,
	CardActions,
	Checkbox,
	CircularProgress,
	IconButton,
	TextField,
	Tooltip,
} from '@mui/material'
import { CheckBox, CheckBoxOutlineBlank, DeveloperBoard, SelectAll } from '@material-ui/icons'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import { getInvestmentsByProject } from 'local_redux/actions/investmentActions'
import { sendProjectSettlementEmail } from 'local_redux/actions/projectAction'
import { getAllFinishedProjects } from 'local_redux/actions/finishedProjectAction'
import { INVESTMENTS_BY_PROJECT_RESET } from 'local_redux/constants/investmentConstants'
import { SEND_PROJECT_SETTLEMENT_EMAIL_RESET } from 'local_redux/constants/projectConstant'
import styles from './styles/ProjectSettlementScreenStyles'

const useStyles = makeStyles(styles)

const ProjectSettlementScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [projects, setProjects] = useState([])
	const [investments, setInvestments] = useState([])
	const [projectSelected, setProjectSelected] = useState(null)
	const [investmentsSelected, setInvestmentsSelected] = useState([])
	const [alert, setAlert] = useState(null)

	const { successFinishedProjectList, finishedProjectListData } = useSelector(
		({ finishedProjectList }) => finishedProjectList,
	)
	const { successInvestmentsByProject, investmentsByProject } = useSelector(
		({ investmentsByProject }) => investmentsByProject,
	)
	const { loadingSendProjectSettlementEmail, successSendProjectSettlementEmail, errorSendProjectSettlementEmail } =
		useSelector(({ sendProjectSettlementEmail }) => sendProjectSettlementEmail)

	useEffect(() => {
		dispatch(getAllFinishedProjects())
	}, [])
	useEffect(() => {
		if (successFinishedProjectList) {
			setProjects(
				finishedProjectListData?.map((item) => ({
					_id: item?.project?._id,
					projectAddress: item?.project?.projectAddress,
				})),
			)
		}
	}, [successFinishedProjectList])
	useEffect(() => {
		if (projectSelected) {
			dispatch(getInvestmentsByProject(projectSelected?._id))
		}
	}, [projectSelected])
	useEffect(() => {
		if (successInvestmentsByProject) {
			setInvestments(investmentsByProject)
		}
	}, [successInvestmentsByProject])

	useEffect(() => {
		if (successSendProjectSettlementEmail) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					onConfirm={() => confirmSuccess()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Operacion realizada Correctamente
				</SweetAlert>,
			)
		}
	}, [successSendProjectSettlementEmail])

	const confirmSuccess = () => {
		setProjectSelected(null)
		setInvestments([])
		setInvestmentsSelected([])
		dispatch({ type: INVESTMENTS_BY_PROJECT_RESET })
		dispatch({ type: SEND_PROJECT_SETTLEMENT_EMAIL_RESET })
		setAlert(null)
	}

	const handleSubmit = () => {
		dispatch(
			sendProjectSettlementEmail({
				project: projectSelected._id,
				investments: investmentsSelected?.map((item) => ({ _id: item?._id })),
			}),
		)
	}

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<DeveloperBoard />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<div style={{ width: '60%' }}>
								<GridContainer>
									<GridItem xs={12}>
										<Autocomplete
											ListboxProps={{
												className: classes.scrollbar,
											}}
											className={classes.autoComplete}
											disablePortal
											options={projects}
											value={projectSelected || {}}
											onChange={(event, newValue) => {
												setProjectSelected(newValue)
												setInvestmentsSelected([])
											}}
											noOptionsText={<span>{'Sin resultados'}</span>}
											getOptionLabel={(e) => (e?.projectAddress ? e?.projectAddress : '')}
											renderInput={(params) => (
												<TextField {...params} placeholder='Seleccionar proyecto *' variant='standard' />
											)}
										/>
									</GridItem>

									<GridItem xs={12} style={{ marginTop: '20px' }}>
										<div
											style={{
												border: '3px solid #e1dcdc',
												padding: '14px 25px 35px',
												borderRadius: '6px',
												margin: '22px 0',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Autocomplete
												ListboxProps={{
													className: classes.scrollbar,
												}}
												disabled={investments?.length === 0}
												multiple
												fullWidth
												disableCloseOnSelect
												limitTags={2}
												className={classes.autoComplete}
												disablePortal
												options={investments}
												value={investmentsSelected}
												onChange={(event, newValue) => {
													setInvestmentsSelected(newValue)
												}}
												noOptionsText={<span>{'Sin resultados'}</span>}
												getOptionLabel={(e) => `${e?.investorProfile?.businessName} - ${e?.amount}`}
												renderInput={(params) => (
													<TextField {...params} placeholder='Seleccionar Inversiones *' variant='standard' />
												)}
												renderOption={(props, option, { selected }) => (
													<li {...props}>
														<Checkbox
															icon={<CheckBoxOutlineBlank fontSize='small' />}
															checkedIcon={<CheckBox fontSize='small' />}
															style={{ marginRight: 8, color: '#aeaeae' }}
															checked={selected}
														/>
														{`${option?.investorProfile?.businessName} - ${option?.amount}`}
													</li>
												)}
											/>
											<IconButton
												disabled={investments?.length === 0}
												onClick={() => setInvestmentsSelected(investments)}
											>
												<Tooltip title='Seleccionar Totas'>
													<SelectAll />
												</Tooltip>
											</IconButton>
										</div>
									</GridItem>
									<GridItem xs={12}>
										{errorSendProjectSettlementEmail && (
											<SnackbarContent message={errorSendProjectSettlementEmail} color='danger' />
										)}
									</GridItem>
								</GridContainer>
							</div>
						</div>
					</CardBody>
					<CardActions>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
							<Button
								disabled={loadingSendProjectSettlementEmail || !investmentsSelected.length}
								color='primary'
								onClick={handleSubmit}
							>
								Enviar correos de liquidación
							</Button>
						</div>
					</CardActions>
				</Card>
			</GridItem>
			<Backdrop
				sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loadingSendProjectSettlementEmail}
			>
				<CircularProgress color='inherit' />
				Espere un momento por favor...
			</Backdrop>
			{alert}
		</GridContainer>
	)
}

export default ProjectSettlementScreen
