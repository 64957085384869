import { Tooltip } from '@material-ui/core'
import { Visibility } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'

export const InvestorProfileListColumns = ({ showInvestorInfoHandler }) => [
	{
		header: 'Razón Social',
		accessor: 'businessName',
		size: 'sm',
	},
	{
		header: 'Entidad',
		accessor: 'personType',
		size: 'sm',
	},
	{
		header: 'Actions',
		accessor: 'actions',
		size: 'sm',
		cellAlign: 'right',
		headerAlign: 'right',
		cell: ({ row, cell }) => {
			return (
				<div className='actions-right'>
					<Tooltip id='tooltip-top' title='Ver/Editar Perfil' placement='bottom'>
						<Button
							justIcon
							round
							simple
							onClick={() => showInvestorInfoHandler(row.item)}
							color='success'
							className='view'
						>
							<Visibility />
						</Button>
					</Tooltip>
				</div>
			)
		},
	},
]
