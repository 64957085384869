import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { makeStyles, Card, CardActions, Collapse, Typography, Grid } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GridItem from 'components/Grid/GridItem'
import OwnerProfileListByUser from 'components/OwnerProfileListByUser/OwnerProfileListByUser'
import Loader from 'components/Loader/Loader'
import OwnerUserUpdateForm from './ownerUserUpdateForm'
import ProfileCreateModal from './ProfileCreateModal'
import { matchGender } from 'shared/matchData/matchData'
import { INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET } from 'local_redux/constants/investorProfileConstants'
import { OWNER_PROFILE_BY_ID_RESET } from 'local_redux/constants/ownersProfileConstants'
import { registerProfileInvestorByAdmin } from 'local_redux/actions/investorProfileActions'
import { getOwnersProfileListByIdUser } from 'local_redux/actions/ownersProfileActions'
import styles from '../styles/ownerUserAndProfilesDetailStyle'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const useStyles = makeStyles(styles)

const OwnerUserAndProfilesDetail = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const search = useLocation().search
  const ownerIdQuery = new URLSearchParams(search).get('ownerId')

  const initialState = {
    address: '',
    bankAccountNumber: '',
    businessName: '',
    cif: '',
    email: '',
    gender: '',
    lastName: '',
    liquidation: 'pay',
    name: '',
    nif: '',
    personType: '',
    phone: '',
    representativeLastName: '',
    representativeName: '',
    representativeNif: '',
    retention: '',
    userId: '',
    userGender: '',
    prescriber: '',
    province: 'Madrid',
    location: 'Madrid',
    postalCode: '28001',
    country: '',
    residenceCertificate: '',
    fiscalNationality: '',
  }
  const [data, setData] = useState(initialState)
  const [expanded, setExpanded] = useState(true)
  const [ownerProfilesList, setOwnerProfilesList] = useState([])
  const [createModal, setCreateModal] = useState(false)

  const { loadingOwnerProfileById, successOwnerProfileById, ownerProfileByIdData } = useSelector(
    (state) => state.ownerProfileByIdUser
  )
  const { successInvestorProfileByAdminRegister, investorProfileByAdminRegisterData } = useSelector(
    (state) => state.investorProfileByAdminRegister
  )
  const { successInvestorProfileUpdate, investorProfileUpdateData } = useSelector(
    (state) => state.investorProfileUpdate
  )
  const { successOwnerProfileDelete, ownerProfileDeleteData } = useSelector((state) => state.ownerProfileDelete)
  const { userDetail } = useSelector((state) => state.userDetails)

  useEffect(() => {
    if (!successOwnerProfileById) {
      dispatch(getOwnersProfileListByIdUser(ownerIdQuery))
    } else {
      setOwnerProfilesList(ownerProfileByIdData)
    }
  }, [ownerIdQuery, successOwnerProfileById])
  useEffect(() => {
    if (successInvestorProfileByAdminRegister) {
      setOwnerProfilesList([...ownerProfilesList, investorProfileByAdminRegisterData])
    }
  }, [successInvestorProfileByAdminRegister])
  useEffect(() => {
    if (successInvestorProfileUpdate) {
      const dataUpdated = ownerProfilesList.map((profile) => {
        if (profile._id === investorProfileUpdateData._id) {
          return investorProfileUpdateData
        }
        return profile
      })

      setOwnerProfilesList(dataUpdated)
    }
  }, [successInvestorProfileUpdate])
  useEffect(() => {
    if (successOwnerProfileDelete) {
      const dataDeleted = ownerProfilesList.filter((profile) => profile._id !== ownerProfileDeleteData.id)
      setOwnerProfilesList(dataDeleted)
    }
  }, [successOwnerProfileDelete])
  useEffect(() => {
    return () => {
      dispatch({ type: OWNER_PROFILE_BY_ID_RESET })
      dispatch({ type: INVESTOR_PROFILE_BY_ADMIN_REGISTER_RESET })
    }
  }, [dispatch])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const handleOpenClose = () => {
    setCreateModal(!createModal)
  }
  const handleCreateProfile = (e) => {
    const matchData = {
      ...data,
      userId: userDetail._id,
      userGender: userDetail.gender,
      gender: matchGender(data.gender, false),
    }
    dispatch(registerProfileInvestorByAdmin(matchData))
  }

  return (
    <>
      {loadingOwnerProfileById ? (
        <GridItem className={classes.Loader}>
          <Loader />
        </GridItem>
      ) : (
        ownerProfilesList && (
          <>
            <GridItem xs={12}>
              <OwnerUserUpdateForm userId={ownerIdQuery} />
            </GridItem>

            {ownerProfilesList?.length > 0 ? (
              <Grid item xs={12} sm={8} className={classes.marginAuto}>
                <Card>
                  <CardActions disableSpacing>
                    <GridItem xs={11}>
                      <Typography variant='h6'>Perfiles</Typography>
                    </GridItem>
                    <ExpandMore onClick={handleOpenClose}>
                      <PersonAdd />
                    </ExpandMore>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label='show more'
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded} timeout='auto' unmountOnExit>
                    {expanded && <OwnerProfileListByUser profiles={ownerProfilesList} />}
                  </Collapse>
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12} sm={8} className={classes.root + ' ' + classes.marginAuto}>
                <Card>
                  <CardActions disableSpacing>
                    <Typography variant='h6'>Sin Perfiles </Typography>
                    <ExpandMore onClick={handleOpenClose}>
                      <PersonAdd />
                    </ExpandMore>
                  </CardActions>
                </Card>
              </Grid>
            )}
          </>
        )
      )}

      {createModal && (
        <ProfileCreateModal
          setData={setData}
          data={data}
          createModal={createModal}
          handleOpenClose={handleOpenClose}
          handleCreateProfile={handleCreateProfile}
          initialState={initialState}
        />
      )}
    </>
  )
}

export default OwnerUserAndProfilesDetail
