import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { ReportGmailerrorred, Search } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Loader from 'components/Loader/Loader'
import FilterInvesment from './components/FilterInvesment'
import FilterProfile from './components/FilterProfile'
import VideoEvolutionProjectModal from 'components/VideoEvolutionProject/VideoEvolutionProjectModal'
import MakeASearch from './components/MakeASearch'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import ReinvestmentModalv2 from './components/ReinvestmentModalv2'
import MyInvestmentResume from './components/MyInvestmentResume'
import VideoProjectModal from './components/VideoProjectModal'
import GenerateRCModal from './components/GenerateRCModal'
import { investmentsActiveColumns, investmentsFinishColumns } from './config/investments-columns-headers'
import { investmentFinishedMapper } from './mappers/investments-finished-mapper'
import { investmentsActiveMapper } from './mappers/investments-active-mapper'
import { getInvestmentsByUserId } from 'local_redux/actions/investmentActions'
import { RE_INVESTMENT_REGISTER_RESET } from 'local_redux/constants/investmentConstants'
import styles from './styles/myInvestmentsScreenStyles'

const useStyles = makeStyles(styles)

const MyInvestmentsScreen = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const tableId = 1

	const [filter, setFilter] = useState('Activas')
	const [filterP, setFilterP] = useState('0')
	const [data, setData] = useState([])
	const [openVideo, setOpenVideo] = useState(false)
	const [openCoverVideo, setOpenCoverVideo] = useState(false)
	const [openReinvestmentModal, setOpenReinvestmentModal] = useState(false)
	const [showReinvestAdminInfo, setShowReinvestAdminInfo] = useState({})
	const [dataVideo, setDataVideo] = useState(null)
	const [dataCoverVideo, setDataCoverVideo] = useState(null)
	const [showGenerateRCModal, setShowGenerateRCModal] = useState(false)
	const [idForRetentionCertificate, setIdForRetentionCertificate] = useState(null)

	const { loadingInvestmentsByUser, successInvestmentsByUser, errorInvestmentsByUser, investmentsByUserData } =
		useSelector((state) => state.investmentsByUser)
	const { userInfo } = useSelector((state) => state.userLogin)
	const { reinvestmentRegisterSuccess } = useSelector((state) => state.reInvestmentRegister)

	useEffect(() => {
		if (investmentsByUserData && successInvestmentsByUser) {
			const investmentActives = investmentsByUserData.filter((investment) =>
				investment.investmentType === 'PDL'
					? !investment.liquidated || investment.status === 'active'
					: investment.project.status !== 'finished' && investment.project.status !== 'liquidated',
			)

			const investmentFinished = investmentsByUserData.filter(
				(investment) =>
					investment.project.status === 'finished' ||
					investment.project.status === 'liquidated' ||
					investment.liquidated,
			)

			if (investmentActives?.length) {
				handleSelectedInvestments(investmentActives)
			}
			if (investmentFinished?.length) {
				handleSelectedInvestments(investmentFinished, true)
			}
		}
	}, [investmentsByUserData, successInvestmentsByUser])
	useEffect(() => {
		if (reinvestmentRegisterSuccess) {
			dispatch({ type: RE_INVESTMENT_REGISTER_RESET })
			handleCloseReinvestAdminModal()
			dispatch(getInvestmentsByUserId(userInfo._id, filter, filterP))
		}
	}, [reinvestmentRegisterSuccess])
	useEffect(() => {
		dispatch(getInvestmentsByUserId(userInfo._id, filter, filterP))
	}, [filter, filterP])

	const videoShowHandler = (item, type) => {
		if (type === 'images') {
			setOpenVideo(true)
			setDataVideo(item)
		} else {
			setOpenCoverVideo(true)
			setDataCoverVideo(item)
		}
	}
	function handleSelectedInvestments(investments, finished) {
		const myInvestments = finished
			? investmentFinishedMapper(
					investments,
					videoShowHandler,
					navigate,
					userInfo,
					openModal,
					setShowGenerateRCModal,
					setIdForRetentionCertificate,
			  )
			: investmentsActiveMapper(investments, videoShowHandler, navigate)

		if (filterP && filterP !== '0') {
			setData(myInvestments.filter((item) => item.profileId === filterP))
		} else {
			setData(myInvestments)
		}
	}
	function handleCloseReinvestAdminModal() {
		setShowReinvestAdminInfo({})
		setOpenReinvestmentModal(false)
	}
	function openModal(investment) {
		setShowReinvestAdminInfo(investment)
		setOpenReinvestmentModal(true)
	}
	const handleCloseGenerateModal = () => {
		setShowGenerateRCModal(false)
		setIdForRetentionCertificate(null)
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={10} md={9} lg={9}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Search />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<GridContainer className={classes.gridContainer}>
							<GridItem xs={12} sm={6} className={classes.filterInputsRoot}>
								<FilterInvesment filter={filter} setFilter={setFilter} />
							</GridItem>
							<GridItem xs={12} sm={6} className={classes.filterInputsRoot}>
								<FilterProfile filterP={filterP} setFilterP={setFilterP} filter={filter} />
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
			<GridItem xs={12}>
				<Card className={classes.cardStyle}>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<ReportGmailerrorred />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<GridContainer className={classes.gridContainer} style={{ justifyContent: 'center' }}>
							{loadingInvestmentsByUser ? (
								<Loader />
							) : filter ? (
								<GridItem md={12} sm={12} xs={12}>
									<ReactVirtualizedTable
										tableId={tableId}
										columns={
											filter === 'Activas'
												? investmentsActiveColumns
												: filter === 'Finalizadas' && investmentsFinishColumns
										}
										data={data}
										loading={Boolean(loadingInvestmentsByUser)}
										success={Boolean(successInvestmentsByUser)}
										error={errorInvestmentsByUser}
										emptyText={'No se encontraron inversiones.'}
									/>
								</GridItem>
							) : (
								<GridItem xs={12}>
									<MakeASearch />
								</GridItem>
							)}
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
			{filter && data?.length > 0 && !loadingInvestmentsByUser && (
				<GridItem xs={12}>
					<MyInvestmentResume filter={filter} tableId={tableId} />
				</GridItem>
			)}
			{/* {open && <DownloadCertificateModal data={dataDownload} open={open} setOpen={setOpen} />} */}
			{openVideo && dataVideo && (
				<VideoEvolutionProjectModal setOpenVideo={setOpenVideo} openVideo={openVideo} dataVideo={dataVideo} />
			)}
			{openCoverVideo && dataCoverVideo && (
				<VideoProjectModal setOpenVideo={setOpenCoverVideo} openVideo={openCoverVideo} dataVideo={dataCoverVideo} />
			)}
			{openReinvestmentModal && (
				<ReinvestmentModalv2
					openReinvestmentModal={openReinvestmentModal}
					setOpenReinvestmentModal={setOpenReinvestmentModal}
					handleCloseReinvestAdminModal={handleCloseReinvestAdminModal}
					showReinvestAdminInfo={showReinvestAdminInfo}
				/>
			)}
			{showGenerateRCModal && (
				<GenerateRCModal open={showGenerateRCModal} close={handleCloseGenerateModal} id={idForRetentionCertificate} />
			)}
		</GridContainer>
	)
}

export default MyInvestmentsScreen
