import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { downloadBackupDir } from 'local_redux/actions/backupActions'
import styles from '../styles/downloadDirModalStyles'

const useStyles = makeStyles(styles)

const DownloadDirModal = ({ handleCloseDownloadModal, downloadModal, showDirInfo }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { loadingDownloadDir, successDownloadDir, downloadDirData } = useSelector((state) => state.backupDownloadDir)

  const handleDownload = (item) => {
    dispatch(downloadBackupDir(item))
  }
  
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={downloadModal}
      keepMounted
      onClose={handleCloseDownloadModal}
      aria-labelledby='project-modal-delete-title'
      aria-describedby='project-modal-delete-description'
    >
      <DialogTitle id='project-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseDownloadModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Descargar Directorio</h4>
      </DialogTitle>

      <DialogContent id='project-modal-delete-description' className={classes.modalBody}>
        <GridContainer>
          <GridItem xs={12}>
            <Typography>
              ¿Está seguro que desea descargar el directorio <strong>{showDirInfo.matchValue}</strong>?
            </Typography>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button
              color='primary'
              block
              onClick={() => handleDownload({ filter: showDirInfo.filter, dir: showDirInfo.value })}
            >
              Descargar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button block onClick={handleCloseDownloadModal}>
              Cancelar
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default DownloadDirModal
