import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { PictureAsPdf, EuroSymbol } from '@mui/icons-material'
import { KeyboardReturn } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import InvestmentByAdminModal from 'components/InvestmentByAdminModal/InvestmentByAdminModal'
import InvestmentModal from 'components/InvestmentModal/InvestmentModal'
import ModalConfirmationInvestment from 'components/ModalConfirmationInvestment/ModalConfirmationInvestment'
import ProjectTimeProgressBars from 'components/ProjectTimeProgressBar/ProjectTimeProgressBar'
import DocumentsContainer from 'components/DocumentsContainer/DocumentsContainer'
import DocumentsHandler from 'components/DocumentsHandler/DocumentsHandler'
import ProjectDetailsSkeleton from './ProjectDetailsSkeleton'
import role from 'config/roles/roles'
import { matchCurrencyToDecimal, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { matchProjectStatus, matchDate } from 'shared/matchData/matchData'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { PROJECT_BY_ID_RESET } from 'local_redux/constants/projectConstant'
import { getProjectById } from 'local_redux/actions/projectAction'
import styles from '../styles/projectDetailsStyles'

const useStyles = makeStyles(styles)

const ProjectDetails = ({ projectId, projects }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()

	const [totalInvertible, setTotalInvertible] = useState(0)
	const [totalAmount, setTotalAmount] = useState(0)
	const [investmentModal, setInvestmentModal] = useState(false)
	const [showInvestmentInfo, setShowInvestmentInfo] = useState({})
	const [project, setProject] = useState({})
	const [alert, setAlert] = useState(null)
	const [transparencyDocuments, setTransparencyDocuments] = useState([])

	const { loadingProjectById, successProjectById, projectByIdData } = useSelector((state) => state.projectById)
	const { investmentRegisterData, investmentRegisterSuccess } = useSelector((state) => state.investmentRegister)
	const { investorsProfileListData } = useSelector((state) => state.investorsProfileList)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!successProjectById) dispatch(getProjectById(projectId))
		if (successProjectById) setProject(projectByIdData)
	}, [successProjectById])

	useEffect(() => {
		let total = 0
		project?.investments?.forEach((item) => {
			total = total + Number(matchCurrencyToDecimal(item.amount))
		})
		let amountInvertible = Number(matchCurrencyToDecimal(project?.maxInvertible)) - total
		setTotalInvertible(matchNumberToCurrency(amountInvertible))
		setTotalAmount(total)
		const documents = [
			{
				name: 'Escritura de compra',
				value: project.deedOfPurchase,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Nota simple previa a compra',
				value: project.simpleNotePriorToPurchase,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Nota simple inmueble comprado',
				value: project.simpleNotePurchasedProperty,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Gastos de adquisición',
				value: project.acquisitionExpenses,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Facturas de gastos',
				value: project.billsOfExpenses,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Escritura de venta',
				value: project.deedOfSale,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
			{
				name: 'Plano de estado reformado',
				value: project.blueprintEA,
				onlyView: true,
				isDocument: true,
				uploadIcon: '',
			},
		].filter((item) => item.value)

		setTransparencyDocuments(documents)
	}, [project])

	useEffect(() => {
		if (investmentRegisterSuccess) {
			handleModalConfirmation()
			setProject({ ...project, investments: [...project.investments, investmentRegisterData] })
		}
	}, [investmentRegisterSuccess])

	useEffect(() => {
		return () => dispatch({ type: PROJECT_BY_ID_RESET })
	}, [dispatch])

	const showInvestmentHandler = () => {
		setShowInvestmentInfo({ ...project, expand: false })
		setInvestmentModal(true)
	}
	const handleCloseInvestmentModal = () => {
		setInvestmentModal(false)
		setShowInvestmentInfo({})
	}

	const handleModalConfirmation = () => {
		if (investmentRegisterSuccess) {
			if (userInfo.role === role.INVESTOR_ROLE)
				setAlert(
					<ModalConfirmationInvestment
						title={'Inversión realizada correctamente!'}
						text={
							'En unos minutos recibirá el contrato por correo electrónico para su firma vía docuSign. En él encontrará también las instrucciones para realizar el ingreso. Por favor tenga en cuenta que su inversión es efectiva desde que el contrato está firmado y el importe de inversión se hace efectivo en nuestra cuenta corriente. Muchas gracias por su confianza en nosotros.'
						}
						setAlert={setAlert}
					/>,
				)
			else {
				let nameText = ''
				investorsProfileListData.map(
					(investor) => investor._id === investmentRegisterData.investorProfile && (nameText = investor.businessName),
				)

				const dataText = `En unos minutos el usuario ${nameText} recibirá un correo electronico con el contrato a su nombre para que pueda firmarlo vía docuSign. En él encontrará también las instrucciones para realizar el ingreso. Por favor recuerdele al usuario que la inversión será efectiva desde que el contrato este firmado y el importe de inversión se hace efectivo en nuestra cuenta corriente.`
				setAlert(
					<ModalConfirmationInvestment
						title={'Inversión realizada correctamente!'}
						text={dataText}
						setAlert={setAlert}
					/>,
				)
			}
		}
	}

	return (
		<Card className={classes.cardStyles}>
			<CardBody>
				<GridContainer>
					{loadingProjectById ? (
						<GridItem xs={12} sm={11} className={classes.marginAuto}>
							<ProjectDetailsSkeleton />
						</GridItem>
					) : (
						project?._id && (
							<GridItem xs={12} sm={12} className={classes.marginAuto}>
								<GridContainer>
									<GridItem xs={12}>
										<h1 className={classes.title}>{project?.projectAddress}</h1>
									</GridItem>
									<GridItem xs={12} className={classes.detailImage}>
										{/* <img src={`${axios.defaults.baseURL}/${project?.coverImage}`} className={classes.imageMask} /> */}
										<img src={getUrlToOpenFile(project?.coverImage, userInfo)} className={classes.imageMask} />
									</GridItem>
									<GridItem xs={12} style={{ margin: '0 0 35px' }}>
										{Number(matchCurrencyToDecimal(totalInvertible)) < 1 ? (
											<Typography align='center' variant='body1' className={classes.numbersTitle}>
												Total invertido {matchNumberToCurrency(totalAmount)}
											</Typography>
										) : (
											<Typography align='center' variant='body1' className={classes.numbersTitle}>
												{!project.invertible && userInfo.role === role.INVESTOR_ROLE
													? `Total invertido  ${project.maxInvertible}`
													: `Usted puede invertir: ${totalInvertible}`}
											</Typography>
										)}
									</GridItem>
									<GridItem xs={12}>
										<ProjectTimeProgressBars
											startDate={project?.startDateUpdated}
											endDate={project?.finishDateUpdated}
										/>
									</GridItem>
									<GridItem xs={12} style={{ margin: '20px 0 35px' }}>
										<Grid container direction='row' spacing={5}>
											<Grid item xs={12} sm={6}>
												<div className={classes.infoDiv}>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1' className={classes.detailTitle}>
															Según Proyecto
														</Typography>
													</GridItem>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1'>Fecha de Inicio</Typography>
														<Typography variant='body2' className={classes.detailCardSubtitle}>
															{matchDate(project?.startDateDossier)}
														</Typography>
													</GridItem>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1'>Fecha Fin</Typography>
														<Typography variant='body2' className={classes.detailCardSubtitle}>
															{matchDate(project?.finishDateDossier)}
														</Typography>
													</GridItem>
												</div>
											</Grid>
											<Grid item xs={12} sm={6}>
												<div className={classes.infoDiv}>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1' className={classes.detailTitle}>
															Información Actualizada
														</Typography>
													</GridItem>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1'>Fecha de Inicio</Typography>
														<Typography variant='body2' className={classes.detailCardSubtitle}>
															{matchDate(project?.startDateUpdated)}
														</Typography>
													</GridItem>
													<GridItem xs={12} className={classes.infoItems}>
														<Typography variant='body1'>Fecha Fin</Typography>
														<Typography variant='body2' className={classes.detailCardSubtitle}>
															{matchDate(project?.finishDateUpdated)}
														</Typography>
													</GridItem>
												</div>
											</Grid>
										</Grid>
									</GridItem>

									<GridItem xs={12} className={classes.infoItems}>
										<Typography variant='body2'>Estado</Typography>
										<Typography variant='body2' className={classes.detailCardSubtitle}>
											{matchProjectStatus(project?.status)}
										</Typography>
									</GridItem>
									<GridItem xs={12}>
										<GridContainer justifyContent='center'>
											{transparencyDocuments.length > 0 && (
												<GridItem xs={12}>
													<DocumentsContainer title='Transparencia'>
														<DocumentsHandler fields={transparencyDocuments} />
													</DocumentsContainer>
												</GridItem>
											)}
										</GridContainer>
									</GridItem>
									<GridItem xs={12} sm={project.projectPdf ? 4 : 6}>
										<Button
											color='primary'
											onClick={() => {
												navigate('/admin/projects')
											}}
											block
										>
											<KeyboardReturn /> Volver
										</Button>
									</GridItem>
									<GridItem xs={12} sm={project.projectPdf ? 4 : 6}>
										{Number(matchCurrencyToDecimal(totalInvertible)) > 0 &&
											project.invertible &&
											userInfo.role === role.INVESTOR_ROLE && (
												<Button
													color='primary'
													onClick={(e) => {
														showInvestmentHandler()
													}}
													block
												>
													<EuroSymbol fontSize='inherit' /> Invertir
												</Button>
											)}
										{userInfo.role !== role.INVESTOR_ROLE && (
											<Button
												color='primary'
												disabled={!project.invertible}
												onClick={(e) => {
													showInvestmentHandler()
												}}
												block
											>
												<EuroSymbol fontSize='inherit' /> Invertir
											</Button>
										)}
									</GridItem>

									<GridItem xs={12} sm={4}>
										{project.projectPdf && (
											<Button
												onClick={() =>
													window.open(
														// `${axios.defaults.baseURL}/${project.projectPdf}?code=${generateTokenCode(userInfo.token)}`,
														getUrlToOpenFile(project.projectPdf, userInfo),
													)
												}
												color='primary'
												block
											>
												<PictureAsPdf fontSize='inherit' style={{ marginTop: '-3px' }} /> Ver Dossier
											</Button>
										)}
									</GridItem>
								</GridContainer>
							</GridItem>
						)
					)}

					{investmentModal &&
						(userInfo.role === role.INVESTOR_ROLE ? (
							<InvestmentModal
								handleCloseInvestmentModal={handleCloseInvestmentModal}
								investmentModal={investmentModal}
								showInvestmentInfo={showInvestmentInfo}
							/>
						) : (
							<InvestmentByAdminModal
								handleCloseInvestmentByAdminModal={handleCloseInvestmentModal}
								investmentByAdminModal={investmentModal}
								showInvestmentByAdminId={showInvestmentInfo}
							/>
						))}
					{alert}
				</GridContainer>
			</CardBody>
		</Card>
	)
}

export default ProjectDetails
