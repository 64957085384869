import { Tooltip } from '@material-ui/core'
import { UploadFile, BorderColor, Visibility, Delete } from '@mui/icons-material'
import { NoteAdd } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import { openModal } from 'shared/helpers/modal-helper'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'

export const investmentByInvestorColumn = (
	showDeleteInvestmentDateHandler,
	showIncomeUpdateHandler,
	setIdInvestment,
	userInfo,
	setShowUploadContractFileInfo,
	setUploadContractFileAdminModal,
	setShowSignedInfo,
	setSignedAdminModal,
	setShowDetailInvestmentAdminInfo,
	setDetailInvestmentAdminModal,
	setShowDeleteInvestmentInfo,
	setDeleteInvestmentAdminModal,
	classes,
) => {
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'businessName',
			cell: ({ row, cell }) => {
				return (
					<div
						onClick={() => window.open(`/admin/investor-list-profiles?profileId=${row.investorProfileId}`)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{row.businessName}
					</div>
				)
			},
			size: 'sm',
		},
		{
			header: 'Email',
			accessor: 'email',
			size: 'sm',
			hidden: true,
		},
		{
			header: 'Dirección de Proyecto',
			accessor: 'projectAddress',

			size: 'md',
		},
		{
			header: 'Fecha',
			accessor: 'investmentDate',
			type: 'date',
			nullValue: 'No establecido',
			size: 'sm',
		},
		{
			header: 'Importe',
			accessor: 'amount',
			type: 'currency',
			size: 'sm',
		},
		{
			header: 'Ingreso Recibido',
			accessor: 'incomeRecived',
			nullValue: 'No establecido',
			cell: ({ row, cell }) => {
				let isAuthorizedUser = userInfo?.isSupport || userInfo?.isContabilidad
				return (
					<>
						{row.incomeRecived !== 'Confirmar ingreso' ? (
							<>
								{cell}
								{isAuthorizedUser && (
									<Tooltip title='Eliminar Ingreso Recibido' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() =>
												showDeleteInvestmentDateHandler({
													...row,
													_id: row.id,
												})
											}
											color='danger'
											className='delete-date'
										>
											<Delete />
										</Button>
									</Tooltip>
								)}
							</>
						) : (
							<p
								style={{ cursor: 'pointer', color: '#4646c3' }}
								onClick={() => [showIncomeUpdateHandler({ ...row, _id: row.id }), setIdInvestment(row.id)]}
							>
								{cell}
							</p>
						)}
					</>
				)
			},
			size: 'sm',
		},
		{
			header: 'Estado de Contrato',
			accessor: 'singedContract',
			size: 'sm',
			cell: ({ row, cell }) => {
				return (
					<>
						{row.item.itWasSigned ? (
							row.item.signed && row.item.contractUrl ? (
								<>
									Firmado
									<Tooltip id='tooltip-top' title='Ver contrato' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() => window.open(getUrlToOpenFile(row.item.contractUrl, userInfo))}
											color='success'
											className='view'
										>
											<Visibility />
										</Button>
									</Tooltip>
								</>
							) : (
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									Pendiente de Firma
									<Tooltip id='tooltip-top' title='Subir contrato' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() =>
												openModal(row.item, setShowUploadContractFileInfo, setUploadContractFileAdminModal)
											}
											color='success'
											className='view'
										>
											<UploadFile />
										</Button>
									</Tooltip>
								</div>
							)
						) : (
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								Firma y Contrato pendientes
								{userInfo?.isSupport && (
									<Tooltip id='tooltip-top' title='Agregar ID del Contrato' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() => openModal(row.item, setShowSignedInfo, setSignedAdminModal)}
											color='success'
											className='view'
										>
											<BorderColor />
										</Button>
									</Tooltip>
								)}
							</div>
						)}
					</>
				)
			},
		},
		{
			header: 'A fin de Proyecto',
			accessor: 'liquidation',
			size: 'sm',
		},
		{
			header: 'Estado',
			accessor: 'status',
			size: 'sm',
		},
		{
			header: 'Gestionado por',
			accessor: 'managedBy',
			size: 'sm',
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row, cell }) => {
				return (
					<OptionsMenuTable
						options={[]
							.concat(
								<Tooltip title='Ver Inversión' placement='bottom'>
									<div
										className={classes.option}
										onClick={() => openModal(row.item, setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)}
									>
										<Visibility />
										<span>Ver Inversión</span>
									</div>
								</Tooltip>,
							)
							.concat(
								<Tooltip title='Eliminar Inversión' placement='bottom'>
									<div
										className={classes.option}
										onClick={() => {
											openModal(row.item, setShowDeleteInvestmentInfo, setDeleteInvestmentAdminModal)
											setIdInvestment(row.item._id)
										}}
									>
										<Delete />
										<span>Eliminar</span>
									</div>
								</Tooltip>,
							)}
					/>
				)
			},
		},
	]
}
