import { useState, useContext, useEffect } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Card, CardActions, IconButton, Typography, Collapse } from '@mui/material'
import Button from 'components/CustomButtons/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import ProjectInputs from './components/ProjectInputs'
import InvestmentsTable from './components/InvestmentsTable'
import ProjectListTable from './components/ProjectListTable'
import LiquidatedProjectModal from './components/LiquidatedProjectModal'
import GridContainer from 'components/Grid/GridContainer'
import FinishedDocumentNotExistsModal from './components/FinishedDocumentNotExistsModal'
import ProjectInformationTir from './components/ProjectInformationTir'
import LiquidateValidation from './components/LiquidateValidation'
import UnconfirmedInvestmentAlertModal from './components/UnconfirmedInvestmentAlertModal'
import { LiquidatedProjectContext, LiquidatedProjectProvider } from 'contexts/liquidatedProjectContext'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import styles from './styles/ProjectLiquidateStyles'

const useStyles = makeStyles(styles)

const ExpandMore = styled((props) => {
	const { expand, ...other } = props
	return <IconButton {...other} />
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}))

const ProjectLiquidateScreen = () => {
	return (
		<LiquidatedProjectProvider>
			<LiquidatedProjectChildren />
		</LiquidatedProjectProvider>
	)
}

const LiquidatedProjectChildren = () => {
	const classes = useStyles()

	const [expanded, setExpanded] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
	const [finishedDocumentValidationModal, setFinishedDocumentValidationModal] = useState(false)
	const [finishedDocumentValidationModalInfo, setFinishedDocumentValidationModalInfo] = useState({})
	const [tir, setTir] = useState(0)
	const [tirCoinvestor, setTirCoinvestor] = useState('')

	const {
		alert,
		selectedProject,
		errorFinishedProjectRegister,
		investmentsBySelectedProject,
		isBenefitComplete,
		liquidatedProjectModal,
		loadingFinishedProjectRegister,
		successFinishedProjectRegister,
		scrollRef,
		liquidateValidationModal,
		handleLiquidateValidation,
	} = useContext(LiquidatedProjectContext)

	useEffect(() => {
		if (investmentsBySelectedProject.length > 0) {
			setOpenAlert(investmentsBySelectedProject.filter((item) => item.incomeRecivedDate === undefined).length > 0)
		}
	}, [investmentsBySelectedProject])

	useEffect(() => {
		if (tir > 0 || tir !== '') {
			const TIRCoinvestor = (0.12 + (tir / 100 - 0.12) / 2) * 100
			setTirCoinvestor(TIRCoinvestor < 12 ? tir : TIRCoinvestor)
		} else {
			setTirCoinvestor(0)
		}
	}, [tir])

	const handleLiquidProject = () => {
		if (selectedProject.endofprojectdocumentsList.length <= 0) {
			return openModal(selectedProject, setFinishedDocumentValidationModalInfo, setFinishedDocumentValidationModal)
		}
		handleLiquidateValidation()
	}

	const handleCloseFinishedDocumentValidationModal = () => {
		closeModal(setFinishedDocumentValidationModalInfo, setFinishedDocumentValidationModal)
	}

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.marginCero}>
					<Card>
						<CardActions disableSpacing>
							<GridItem xs={12}>
								<div ref={scrollRef}>
									<Typography variant='h6'>Proyectos Finalizados</Typography>
								</div>
							</GridItem>
							<ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label='show more'>
								<ExpandMoreIcon />
							</ExpandMore>
						</CardActions>
						<Collapse in={expanded} timeout='auto'>
							<Divider />
							<ProjectListTable setExpanded={setExpanded} expanded={expanded} openAlert={openAlert} />
						</Collapse>
					</Card>
				</GridItem>
				{selectedProject._id && <ProjectInputs />}
				{selectedProject._id && (
					<ProjectInformationTir
						tir={tir}
						setTir={setTir}
						tirCoinvestor={tirCoinvestor}
						selectedProject={selectedProject}
						setTirCoinvestor={setTirCoinvestor}
					/>
				)}
				<InvestmentsTable tirCoinvestor={tirCoinvestor} />
				{errorFinishedProjectRegister && (
					<GridItem xs={12}>
						<SnackbarContent message={errorFinishedProjectRegister} color='danger' />
					</GridItem>
				)}
				{investmentsBySelectedProject.length > 0 && (
					<>
						<GridItem>
							<Button
								block
								color={successFinishedProjectRegister ? 'success' : 'primary'}
								disabled={
									investmentsBySelectedProject.length < 1 || isBenefitComplete || loadingFinishedProjectRegister
								}
								className={classes.updateProfileButton}
								onClick={handleLiquidProject}
							>
								{successFinishedProjectRegister
									? 'Proyecto liquidado'
									: loadingFinishedProjectRegister
									? 'Liquidando...'
									: 'Liquidar proyecto'}
							</Button>
						</GridItem>
					</>
				)}
			</GridContainer>

			{liquidatedProjectModal && <LiquidatedProjectModal />}
			{liquidateValidationModal && <LiquidateValidation />}
			{alert}
			{finishedDocumentValidationModal && !openAlert && (
				<FinishedDocumentNotExistsModal
					finishedDocumentValidationModal={finishedDocumentValidationModal}
					handleCloseFinishedDocumentValidationModal={handleCloseFinishedDocumentValidationModal}
					finishedDocumentValidationModalInfo={finishedDocumentValidationModalInfo}
				/>
			)}
			{openAlert && <UnconfirmedInvestmentAlertModal openAlert={openAlert} setOpenAlert={setOpenAlert} />}
		</>
	)
}

export default ProjectLiquidateScreen
