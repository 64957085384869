import { cardTitle, blackColor, hexToRgb } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

const styles = (theme) => ({
	...customCheckboxRadioSwitch,
	cardIconTitle: {
		...cardTitle,
		marginBottom: '0px',
	},
	rootItem: {
		padding: '0 !important',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	label: {
		fontSize: '1.2em',
		color: '#3C4858',
	},
	dropDownItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& > p': {
			marginLeft: '10px',
			marginBottom: '0',
		},
	},
	...modalStyle(theme),
})

export default styles
